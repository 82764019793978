import React, { useContext } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonRouterContext, IonTitle } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './VideoTutorial.scss';
import YoutubeEmbed from '../components/Youtube';

const VideoTutorial: React.FC = () => {

  const ionRouterContext = useContext(IonRouterContext);

  const startApp = async () => { 
    ionRouterContext.push('/app/novedades');
  };

  return (
    <IonPage id="video-tutorial-page">
      <IonHeader translucent={true} no-border className="ion-no-border">
        <IonToolbar color="tertiary">
          <IonTitle>
            Videotutorial
          </IonTitle>          
          <IonButtons slot="end">
            <IonButton color="primary" mode="md" onClick={startApp}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>          
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <YoutubeEmbed embedId="KI1h-CaPwTk" />
      </IonContent>
    </IonPage>
  );
};

export default VideoTutorial;
