import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonButton, IonList, IonItem, IonLabel, IonInput, IonCard, IonCardHeader, IonCardContent, IonIcon, IonFooter, IonCardTitle, IonCardSubtitle, IonLoading, IonProgressBar, IonNote } from '@ionic/react';
import { closeOutline, chevronBackOutline, chevronForwardOutline, lockClosedOutline, mailOpenOutline } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import Log from './../components/Log';
import { CODIGO_LARGO, PASSWORD_LARGO } from '../constantes';
import MensajeError from '../components/MensajeError';
import './RecuperarPassword.scss';

interface RecuperarPasswordProps {
  onDismissModal: () => void;
}

const RecuperarPassword: React.FC<RecuperarPasswordProps> = ({ onDismissModal }) => {

  const [pagina, setPagina] = useState(1);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');
  const [codigo, setCodigo] = useState('');
  const [idUsuario, setIdUsuario] = useState('');
  const [codigoError, setCodigoError] = useState(false);  
  const [loading, setLoading] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [inexistente, setInexistente] = useState(false);
  const [inactivo, setInactivo] = useState(false);
  const [errorEnvio, setErrorEnvio] = useState(false);
  const [errorCodigo, setErrorCodigo] = useState(false);
  const [errorCodigoVencido, setErrorCodigoVencido] = useState(false);
  
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validarEmail = async () => {
    setInexistente(false);
    setInactivo(false);
    if (!email) {
      setEmailError(true);
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setError1('');
    setLoading(true);
    setEmailError(false);
    const url = `${process.env.REACT_APP_API_URL}/usuarios/chequear`;
    axios.post(url, { email: email })
      .then(async () => {
        setPagina(2);
        setLoading(false);
      }).catch((err) => {
        Log.error(
          err,
          undefined,
          `RecuperarPassword.tsx » validarEmail(): Error al intentar validar el Email ${email}`
        );
        let msg = `Error ${err?.response?.status || "desconocido"} al intentar esta operación`;
        const status = err?.response?.status || 0;
        switch (status) {
          case 404:
            setInexistente(true);
            msg = "";
            break;
          case 403:
            setInactivo(true);
            msg = "";
            break;
          default:
            break;
        }
        if (err.message === "Network Error") {
          msg = `No es posible comunicarse con la App en este momento.`;
        }
        if (msg) {
          setError1(msg);
        }
        setLoading(false);
      });
  };

  const enviarCodigo = async () => {
    setError2('');
    setEnviando(true);
    setErrorEnvio(false);
    const url = `${process.env.REACT_APP_API_URL}/usuarios/codigo`;
    axios.post(url, { email: email })
      .then(async () => {
        setEnviando(false);
      }).catch((err) => {
        Log.error(
          err,
          undefined,
          `RecuperarPassword.tsx » enviarCodigo(): Error al intentar enviar un Código al Email ${email}`
        );        
        let msg = `Error ${err?.response?.status || "desconocido"} al intentar esta operación`;
        const status = err?.response?.status || 0;
        switch (status) {
          case 500:
            setErrorEnvio(true);
            msg = "";
            break;
          default:
            break;
        }
        if (err.message === "Network Error") {
          msg = `No es posible comunicarse con la App en este momento.`;
        }
        if (msg) {
          setError2(msg);
        }
        setEnviando(false);
      });
  };

  const validarCodigo = async () => {
    if (!codigo || codigo.length !== CODIGO_LARGO) {
      setCodigoError(true);
      return;
    }
    setError2('');
    setLoading(true);
    setCodigoError(false);
    setErrorCodigo(false);
    setErrorCodigoVencido(false);
    const url = `${process.env.REACT_APP_API_URL}/usuarios/codigo/validar`;
    axios.post(url, { email: email, codigo: codigo })
      .then(async (res) => {
        setIdUsuario(res.data.usuario);
        setPagina(3);
        setLoading(false);
      }).catch((err) => {
        Log.error(
          err,
          undefined,
          `RecuperarPassword.tsx » validarCodigo(): Error al intentar validar el Código [${codigo}] del Email ${email}`
        );
        let msg = `Error ${err?.response?.status || "desconocido"} al intentar esta operación`;
        const status = err?.response?.status || 0;
        switch (status) {
          case 422:
          case 404:
            setErrorCodigo(true);
            msg = "";
            break;
          case 403:
            setErrorCodigoVencido(true);
            msg = "";
            break;            
          default:
            break;
        }
        if (err.message === "Network Error") {
          msg = `No es posible comunicarse con la App en este momento.`;
        }
        if (msg) {
          setError2(msg);
        }
        setLoading(false);
      });
  };

  const onSubmit = (data: any) => {
    if (idUsuario && data.password1) {
      setError3('');
      setLoading(true);
      axios.patch(
        `${process.env.REACT_APP_API_URL}/usuarios/${idUsuario}`, { "password": data.password1 })
        .then(async () => {
          setLoading(false);
          setPagina(4);
        })
        .catch((err) => {
          Log.error(
            err,
            undefined,
            `RecuperarPassword.tsx » onSubmit(): Error al intentar cambiar la password [${data.password1}] del Usuario ${idUsuario}`
          );
          let msg = `Error ${err?.response?.status || "desconocido"} al intentar esta operación`;
          setError3(msg);
          setLoading(false);
        });
    }
  };

  const { control, handleSubmit, getValues, formState: { errors }, reset } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      password1: "",
      password2: "",
    }
  });

  const TOTAL_PAGINAS = 4;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage id="recuperar-password-page">
        <IonHeader translucent={true} no-border>
          <IonToolbar color="tertiary">
            <IonButtons slot="end">
              <IonButton onClick={onDismissModal}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>
              Recuperar Contraseña
            </IonTitle>
          </IonToolbar>
          <IonToolbar color="secondary">
            <IonTitle>Paso {pagina} de {TOTAL_PAGINAS}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonProgressBar value={pagina / TOTAL_PAGINAS}></IonProgressBar>
          {
            pagina === 1 && (
              <>
                <IonCard style={{ boxShadow: "none", border: "1px solid #ddd" }}>
                  <IonCardHeader>
                    <IonCardTitle>
                      Ingrese su Email
                    </IonCardTitle>
                    <IonCardSubtitle>
                      Este paso verificará su Email en AAOT
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem className={emailError ? "ion-invalid" : ""}>
                        <IonLabel position="stacked">Email</IonLabel>
                        <IonInput
                          name="email"
                          type="email"
                          value={email}
                          spellCheck={false}
                          autocapitalize="off"
                          onIonChange={e => {
                            setEmail(e.detail.value!);
                            setEmailError(e.detail.value!.trim() === "");
                          }}
                          required>
                        </IonInput>
                        {emailError && (
                          <IonNote slot="error">Debe indicar un Email válido</IonNote>
                        )}
                      </IonItem>
                    </IonList>
                    {
                      error1 && <MensajeError mensaje={error1} onClose={() => setError1('')} />
                    }
                  </IonCardContent>
                </IonCard>
                {
                  inexistente && (
                    <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                      <IonCardHeader>
                        <IonCardTitle>
                          Usuario inexistente
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem className="ion-activated">
                        <IonLabel>
                          Por favor, revise el email ingresado o contacte con Administración
                        </IonLabel>
                      </IonItem>
                    </IonCard>
                  )
                }
                {
                  inactivo && (
                    <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                      <IonCardHeader>
                        <IonCardTitle>
                          Usuario inactivo
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem className="ion-activated">
                        <IonLabel>
                          Por favor, contacte con Administración
                        </IonLabel>
                      </IonItem>
                    </IonCard>
                  )
                }                               
              </>
            )
          }
          {
            pagina === 2 && (
              <>
              <IonCard style={{ boxShadow: "none", border: "1px solid #ddd" }}>
                  <IonCardHeader>
                    <IonCardTitle>
                      Se enviará un Código a su Email
                    </IonCardTitle>
                    <IonCardSubtitle>
                      Si no lo recibe en unos instantes, verifique la carpeta Spam
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonButton expand="block" mode="md" onClick={enviarCodigo} fill="solid" color="primary">
                      <IonIcon slot="start" icon={mailOpenOutline} />
                      Enviar Código
                    </IonButton>
                  </IonCardContent>
                </IonCard>              
                <IonCard style={{ boxShadow: "none", border: "1px solid #ddd" }}>
                  <IonCardHeader>
                    <IonCardTitle>
                      Ingrese el Código de 4 caracteres recibido
                    </IonCardTitle>
                    <IonCardSubtitle>
                      Ingrese el Código recibido en su buzón de Email
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem className={emailError ? "ion-invalid" : ""}>
                        <IonLabel position="stacked">Código</IonLabel>
                        <IonInput
                          name="codigo"
                          value={codigo}
                          spellCheck={false}
                          autocapitalize="off"
                          maxlength={4}
                          onIonChange={e => {
                            setCodigo(e.detail.value!);
                            setCodigoError(e.detail.value!.trim().length !== CODIGO_LARGO);
                          }}
                          style={{ fontSize: '1.75em', textAlign: 'center' }}
                          required>
                        </IonInput>
                        {codigoError && (
                          <IonNote slot="error">Debe indicar un Código válido</IonNote>
                        )}
                      </IonItem>
                    </IonList>
                    {
                      error2 && <MensajeError mensaje={error2} onClose={() => setError2('')} />
                    }
                  </IonCardContent>
                </IonCard>
                {
                  errorEnvio && (
                    <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                      <IonCardHeader>
                        <IonCardTitle>
                          No se ha podido enviar el Código
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem className="ion-activated">
                        <IonLabel>
                          Por favor, intentelo nuevamente
                        </IonLabel>
                      </IonItem>
                    </IonCard>
                  )
                }
                {
                  errorCodigo && (
                    <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                      <IonCardHeader>
                        <IonCardTitle>
                          El Código no es válido
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem className="ion-activated">
                        <IonLabel>
                          Por favor, revise el Código ingresado y reintente
                        </IonLabel>
                      </IonItem>
                    </IonCard>
                  )
                }
                {
                  errorCodigoVencido && (
                    <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                      <IonCardHeader>
                        <IonCardTitle>
                          El Código ya ha vencido
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem className="ion-activated">
                        <IonLabel>
                          Por favor, genere un nuevo Código y reintente
                        </IonLabel>
                      </IonItem>
                    </IonCard>
                  )
                }
              </>
            )
          }
          {
            pagina === 3 && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #ddd" }}>
                <IonCardHeader>
                  <IonCardTitle>
                    Ingrese su nueva Contraseña
                  </IonCardTitle>
                  <IonCardSubtitle>
                    Por favor, complete los siguientes campos
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                    <IonItem className={errors.password1 ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">Nueva contraseña</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            autocomplete="off"
                            onIonBlur={() => field.onBlur()}
                            onIonChange={(e) => field.onChange(e.detail.value)}
                            style={{ fontSize: '1.25em', textAlign: 'center' }}
                            // placeholder="Ingrese su nueva Contraseña"                            
                          />
                        )}
                        control={control}
                        name="password1"
                        rules={{
                          required: {
                            value: true,
                            message: "Campo requerido"
                          },
                          minLength: {
                            value: PASSWORD_LARGO,
                            message: `La Contraseña debe poseer al menos ${PASSWORD_LARGO} caracteres`
                          }
                        }}
                      />
                      <IonNote slot="error">{errors?.password1?.message}</IonNote>
                    </IonItem>
                    <IonItem className={errors.password2 ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">Repetir nueva contraseña</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            autocomplete="off"
                            onIonBlur={() => field.onBlur()}
                            onIonChange={(e) => field.onChange(e.detail.value)}
                            style={{ fontSize: '1.25em', textAlign: 'center' }}
                            // placeholder="Repita la nueva Contraseña"                            
                          />
                        )}
                        control={control}
                        name="password2"
                        rules={{
                          required: {
                            value: true,
                            message: "Campo requerido"
                          },
                          minLength: {
                            value: PASSWORD_LARGO,
                            message: `La Contraseña debe poseer al menos ${PASSWORD_LARGO} caracteres`
                          },
                          validate: (value) => {
                            const { password1 } = getValues();
                            return password1 === value || "Las Contraseñas no coinciden";
                          }
                        }}
                      />
                      <IonNote slot="error">{errors?.password2?.message}</IonNote>
                    </IonItem>
                  </IonList>
                  {
                    error3 !== "" && <MensajeError mensaje={error3} onClose={() => setError3('')} />
                  }                  
                </IonCardContent>
              </IonCard>
            )
          }
          {
            pagina === 4 && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #009624" }} color="success">
                <IonCardHeader>
                  <IonCardTitle>
                    Contraseña actualizada
                  </IonCardTitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>Ya puede usarla para ingresar a la aplicación</IonLabel>
                </IonItem>
              </IonCard>
            )
          }
          <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={pagina === 1 || pagina === 2  ? "Verificando..." : "Guardando contraseña..."}
            duration={60000}
          />
          <IonLoading
            isOpen={enviando}
            onDidDismiss={() => setEnviando(false)}
            message={"Enviando Código..."}
            duration={60000}
          />          
        </IonContent>
        <IonFooter translucent className="ion-no-border">
          <IonToolbar color="tertiary">
            <IonButtons slot="start">          
              {
                pagina === 2 && (
                  <IonButton onClick={() => {
                      setCodigo('');
                      setPagina(1);
                    }}>
                    <IonIcon slot="start" icon={chevronBackOutline} />
                    Atrás
                  </IonButton>
                )
              }
              {
                pagina === 3 && (
                  <IonButton onClick={() => {
                      reset({
                        password1: "",
                        password2: "",
                      });
                      setPagina(2);
                    }}>
                    <IonIcon slot="start" icon={chevronBackOutline} />
                    Atrás
                  </IonButton>
                )
              }                          
            </IonButtons>
            <IonButtons slot="end">
              {
                pagina === 1 && (
                  <IonButton onClick={validarEmail}>
                    Verificar
                    <IonIcon slot="end" icon={chevronForwardOutline} />
                  </IonButton>
                )
              }
              {
                pagina === 2 && (
                  <IonButton onClick={validarCodigo} disabled={!codigo || codigo.length !== CODIGO_LARGO ? true : false}>
                    Verificar
                    <IonIcon slot="end" icon={chevronForwardOutline} />
                  </IonButton>
                )
              }
              {
                pagina === 3 && (
                  <IonButton color="success" type="submit">
                    Cambiar contraseña
                    <IonIcon slot="end" icon={lockClosedOutline} />
                  </IonButton>
                )
              }              
              {
                pagina === 4 && (
                  <IonButton color="success" onClick={onDismissModal}>
                    Cerrar
                  </IonButton>
                )
              }
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default RecuperarPassword;
