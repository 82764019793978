import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonModal, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { setupConfig } from '@ionic/core';
import { IonReactRouter } from '@ionic/react-router';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MainTabs from './pages/MainTabs';
import { AppContext, AppContextProvider } from './AppContext';
import Login from './pages/Login';
import Cuenta from './pages/Cuenta';
import Tutorial from './pages/Tutorial';
import ContactoPagina from './pages/ContactoPagina';
import RedirectToLogin from './components/RedirectToLogin';

import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

import Loader from './components/Loader';
import AdminTabs from './pages/AdminTabs';
import './App.scss';

import { L10n } from '@syncfusion/ej2-base';
import { loadCldr } from "@syncfusion/ej2-base";
import PrivateRoute from './PrivateRoute';
import VideoTutorial from './pages/VideoTutorial';

// https://www.npmjs.com/package/react-cache-buster
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import SponsorPage from './pages/Sponsor';

// https://ej2.syncfusion.com/react/documentation/daterangepicker/globalization/
// https://www.syncfusion.com/forums/148310/globalization

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/es/ca-gregorian.json'),
  require('cldr-data/main/es/numbers.json'),
  require('cldr-data/main/es/timeZoneNames.json'),
  require('cldr-data/supplemental/weekData.json')
);

L10n.load({
  es: {
    grid: {
      EmptyRecord: 'No hay registros para mostrar',
      Edit: 'Editar',
      Cancel: 'Cancelar',
      Delete: 'Eliminar',
      Update: 'Actualizar',
      OKButton: 'OK',
      CancelButton: 'Cancelar',
      SaveButton: 'Guardar',
      DeleteOperationAlert: 'No hay registro seleccionado para eliminar',
      EditOperationAlert: 'No hay registro seleccionado para editar',
      ConfirmDelete: '¿Seguro que deseas eliminar?',
      EditFormTitle: 'Detalles de ',
      Search: 'Buscar'
    },
    pager: {
      currentPageInfo: '{0} / {1}',
      totalItemsInfo: '({0} filas)',
    },
    datepicker: {
      placeholder: "Selecciona una fecha",
      today: "Hoy"
    },
    daterangepicker: {
      placeholder: 'Rango de fechas',
      startLabel: 'Seleccione Desde',
      endLabel: 'Seleccione Hasta',
      applyText: 'Aceptar',
      cancelText: 'Cancelar',
      selectedDays: 'Días seleccionados',
      days: 'días',
      customRange: 'Rango de fechas'
    }
  }
});

// TODO Revisar
setupConfig({
  mode: 'md',
  rippleEffect: true
});

const IonicApp: React.FC = () => {
  const {
    isLoading,
    isLogged,
    mostrarSponsor,
    setMostrarSponsor
  } = useContext(AppContext);
  return (
    isLoading()
      ? (
        <Loader />
      ) : (
        <>
          <IonApp>
            <IonReactRouter>
              {
                isLogged()
                  ? (
                    <IonSplitPane contentId="main">
                      <Menu />
                      <IonRouterOutlet id="main">
                        <Switch>
                          <PrivateRoute
                            component={MainTabs}
                            path="/app"
                          />
                          <PrivateRoute
                            component={AdminTabs}
                            path="/admin"
                          />
                          <PrivateRoute
                            component={Tutorial}
                            path="/tutorial"
                            exact
                          />
                          <PrivateRoute
                            component={VideoTutorial}
                            path="/videotutorial"
                            exact
                          />
                          <PrivateRoute
                            component={Cuenta}
                            path="/cuenta"
                            exact
                          />
                          <PrivateRoute
                            component={ContactoPagina}
                            path="/contacto"
                            exact
                          />
                          <Route path="/logout" component={RedirectToLogin} />
                          <Redirect to="/app/novedades" />
                        </Switch>
                      </IonRouterOutlet>
                    </IonSplitPane>
                  ) : (
                    // <PublicRoute
                    //   restricted={true}
                    //   component={Login}
                    //   path="/"
                    // />
                    <Route component={Login} />
                  )
              }
            </IonReactRouter>
          </IonApp>
          <IonModal
            swipeToClose={true}
            isOpen={mostrarSponsor}
            className="modal-sponsor"
            onDidDismiss={() => setMostrarSponsor(false)}
          >
            <SponsorPage
              onDismissModal={() => setMostrarSponsor(false)}
            />
          </IonModal>
        </>
      )
  )
}

const App: React.FC = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}      // If false, the library is disabled.
      isVerboseMode={true}          // If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} // If not pass, nothing appears at the time of new version check.
    >
      <AppContextProvider>
        <IonicApp />
      </AppContextProvider>
    </CacheBuster>
  );
};

export default App;
