import React, { useCallback, useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonFooter, IonPage, IonIcon, IonRange } from '@ionic/react';
import Cropper from 'react-easy-crop'
import getCroppedImg from './CropImage'
import './Foto.scss'
import { closeOutline, searchOutline } from 'ionicons/icons';

interface Foto {
  onDismissModal: (foto: any) => void;
}

interface FileUploaderProps {
  onFileSelectSuccess: (foto: any) => void;
  onFileSelectError: (foto: any) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFileSelectSuccess, onFileSelectError }) => {

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];
    if (file.size > 20971520) {
      onFileSelectError({ error: "La imagen no debe superar los 20 MB" });
    } else {
      onFileSelectSuccess(file);
    }
  };

  const buscarFoto = () => {
    (document as any).getElementById("file-uploader").click();
  };

  return (
    <>
      <input id="file-uploader" type="file" accept="image/*" onChange={handleFileInput} style={{ display: 'none' }} />
      <IonButton mode="md" fill="solid" color="success" onClick={buscarFoto}>
        <IonIcon slot="start" icon={searchOutline} />
        Buscar foto
      </IonButton>
    </>
  )
}

const Foto: React.FC<Foto> = ({ onDismissModal }) => {

  // https://codesandbox.io/s/q8q1mnr01w?file=/src/index.js:1031-1280
  const guardar = async () => {
    let imagen = null;
    try {
      imagen = await getCroppedImg(
        preview,
        croppedAreaPixels,
        0 // rotation
      )
    } catch (e) {
      console.error(e);
    }
    onDismissModal(imagen);
  }

  // https://www.pluralsight.com/guides/how-to-use-a-simple-form-submit-with-files-in-react
  const [selectedFile, setSelectedFile] = useState<Blob|null>(null);
  const [preview, setPreview] = useState<string|null>(null);

  // https://www.npmjs.com/package/react-easy-crop  
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  useEffect(() => {
    if (selectedFile) {
      setPreview(URL.createObjectURL(selectedFile));  
    } else {
      setPreview(null);
    }    
  }, [selectedFile]);

  return (
    <IonPage id="page-foto">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonTitle>
            Foto
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismissModal(null)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-main">
        {
          preview && (
            <Cropper
              image={preview}
              crop={crop}
              zoom={zoom}
              aspect={3 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )
        }
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <IonRange
            pin={true}
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            pinFormatter={(value: number) => `${Math.trunc((value * 10) - 10)}`}
            onIonChange={e => setZoom(e.detail.value as number)}
          />
        </IonToolbar>        
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <FileUploader
              onFileSelectSuccess={(file) => setSelectedFile(file)}
              onFileSelectError={({ error }) => alert(error)}
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton mode="md" fill="solid" color="success" onClick={guardar}>
              Guardar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Foto;
