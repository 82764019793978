import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './Sponsor.scss'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import { Navigation, Pagination, Zoom } from 'swiper';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import 'swiper/modules/zoom/zoom.scss';             // Zoom module

interface SponsorProps {
  onDismissModal: () => void;
}

const SponsorPage: React.FC<SponsorProps> = ({ onDismissModal }) => {
  return (
    <IonPage id="page-sponsor">
      <IonHeader translucent={false}>
        <IonToolbar color="primary">
          <IonButtons slot="start">
          </IonButtons>
          <IonTitle>
            Sponsor Bagó
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismissModal()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Swiper
          modules={[Navigation, Pagination, Zoom]}
          navigation
          loop={true}
          zoom={true}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src={`${process.env.REACT_APP_API_FOTOS}/bago-tarjeton-posterior.jpg`} alt="" className="slide-image" width={"100%"} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src={`${process.env.REACT_APP_API_FOTOS}/bago-tarjeton-frente.jpg`} alt="" className="slide-image" width={"100%"} />
            </div>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default SponsorPage;
