import React from 'react';
import { EDITABLE_HACIA_ATRAS_CANTIDAD, EDITABLE_HACIA_ATRAS_UNIDAD } from '../constantes';

interface SinPracticasProps {
  imagen: string;
  mensaje: string;
}

const SinPracticas: React.FC<SinPracticasProps> = ({ imagen, mensaje}) => {
  return (
    <div style={{ height: "300px", textAlign: "center", paddingTop: '30px' }}>
      <img src={imagen} alt="" />
      <p style={{ fontSize: '1.25em', color: '#666' }}>
        {mensaje}
      </p>
      <p style={{ fontSize: '0.9em', color: '#888' }}>{`Pasadas las ${EDITABLE_HACIA_ATRAS_CANTIDAD} ${EDITABLE_HACIA_ATRAS_UNIDAD}, sólo es posible`}</p>
      <p style={{ fontSize: '0.9em', color: '#888' }}>{`consultar las Prácticas desde Estadísticas`}</p>
    </div>
  );
};

export default SinPracticas;
