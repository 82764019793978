import React, { useContext, useState } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonRouterContext } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Swiper as SwiperCore } from 'swiper';
import { arrowForward, closeOutline } from 'ionicons/icons';
import './Tutorial.scss';
import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import { Navigation, Pagination } from 'swiper';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import { AppContext } from '../AppContext';

const Tutorial: React.FC = () => {

  const ionRouterContext = useContext(IonRouterContext);
  const [showSkip, setShowSkip] = useState(true);  
  const {
    setVioTutorial,
  } = useContext(AppContext);

  let swiper: SwiperCore;
 
  const startApp = async () => { 
    setVioTutorial(true);
    ionRouterContext.push('/app/novedades');
  };

  const handleSlideChangeStart = () => { 
    if(!swiper) return;
    setShowSkip(!swiper.isEnd);
  };

  return (
    <IonPage id="tutorial-page">
      <IonHeader translucent={true} no-border className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            {
              showSkip && (
                <IonButton color="primary" mode="md" onClick={startApp}>
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </IonButton>
              )
            }            
          </IonButtons>          
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* https://swiperjs.com/react */}
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          onSwiper={(x) => swiper = x} //setSwiper
          onSlideChangeTransitionStart={handleSlideChangeStart}
        >
          <SwiperSlide>
          <img src={`${process.env.REACT_APP_API_FOTOS}/tutorial_1.jpg`} alt="" className="slide-image" />
            <h2 className="slide-title">App de Residentes</h2>
            <p>
              Esta APP fue creada desde la AAOT para uso de sus socios residentes.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={`${process.env.REACT_APP_API_FOTOS}/tutorial_2.jpg`} alt="" className="slide-image" />
            <h2 className="slide-title">
              Objetivo
            </h2>
            {/* <p>
              Podrán cargar, organizar y documentar sus prácticas médicas desde cualquier lugar y dispositivo, de forma simple. Los módulos fueron armados por tipo de especialidad, diagnóstico, etiología y tratamiento y nomenclatura según el NUN.
            </p> */}
            <p>
              Podrán documentar, sus prácticas médicas desde cualquier lugar y dispositivo, de forma simple. Los módulos fueron armados por tipo de especialidad, según el NUN.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={`${process.env.REACT_APP_API_FOTOS}/tutorial_3.jpg`} alt="" className="slide-image" />
            <h2 className="slide-title">
              Primeros pasos
            </h2>
            <p style={{ marginBottom: 0 }}>
              Para ingresar, deberás registrarte creando un usuario y contraseña y podrás comenzar a utilizarla.
            </p>
            <IonButton fill="clear" onClick={startApp}>
              Ir a la App
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>            
          </SwiperSlide>
          {/* <SwiperSlide>
            <img src="assets/img/medical-app-doctor.png" alt="" className="slide-image" />
            <h2 className="slide-title">Comencemos</h2>
            <IonButton fill="clear" onClick={startApp}>
              Ir a la App
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>
          </SwiperSlide> */}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default Tutorial;
