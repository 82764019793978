import React, { useContext, useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonSpinner, IonModal, IonButton, IonIcon } from '@ionic/react';
import './Estadisticas.scss';
import { AppContext } from '../AppContext';
import axios from 'axios';
import { Practica } from '../models/Practica';
import GrillaDePracticas from '../components/GrillaDePracticas';
import PracticasFiltros from '../components/PracticasFiltros';
import { filterOutline, searchOutline } from 'ionicons/icons';
import LogoAaot from '../components/LogoAaot';
import { defaultFiltros, filtrar, Filtros } from '../models/Filtros';
import Log from '../components/Log';

const Estadisticas: React.FC = () => {

  const [filtros, setFiltros] = useState<Filtros>();
  const [practicas, setPracticas] = useState<Practica[]>([]);
  const [showFiltros, setShowFiltros] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    usuario,
    setMostrarSponsor,
  } = useContext(AppContext);

  useEffect(() => {
    if (usuario) {
      setFiltros(defaultFiltros(usuario));
    }
  }, [usuario]);

  useEffect(() => {
    if (!filtros) {
      return;
    }
    const filter = filtrar(filtros);
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/practicas?${filter}`;
    axios.get(url)
      .then((res) => {
        setPracticas(res.data.data);
        setLoading(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Estadisticas.tsx » useEffect(filtros): Error al intentar filtrar con '${filtros}'`
        );
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [filtros]);

  const onCloseFiltros = (nuevosFiltros: Filtros | null) => {
    setShowFiltros(false);
    if (nuevosFiltros) {
      setFiltros(nuevosFiltros);
    }
  };

  return (
    <IonPage id="estadisticas">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Estadísticas</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowFiltros(true)}>
              Filtros
              <IonIcon icon={filterOutline} slot="end" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonToolbar className="toolbar-sponsor ion-no-padding" style={{ cursor: "pointer" }} onClick={() => setMostrarSponsor(true)}>
        {/* <img className="ion-hide-sm-up"   src={`${process.env.REACT_APP_API_FOTOS}/bannerBDolor_1200x400.png`} alt="" /> */}
        {/* <img className="ion-hide-sm-down" src={`${process.env.REACT_APP_API_FOTOS}/bannerBDolor_1200x200.png`} alt="" /> */}
        <img src={`${process.env.REACT_APP_API_FOTOS}/bannerBDolor_1200x200.png`} alt="" />
        <IonButtons slot="end">
          <IonButton fill="solid" shape="round" size="small" color="primary" onClick={() => setMostrarSponsor(true)} style={{ textTransform: "none" }}>
            <IonIcon slot="icon-only" icon={searchOutline} size="small" />
            {/* <span className="ion-hide-sm-up">Más info</span> */}
            <span className="ion-hide-sm-down">VDM Línea dolor Bagó</span>
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent fullscreen={true}>
        {
          loading
            ? (
              <IonSpinner name="crescent" color="secondary" />
            ) : (
              <GrillaDePracticas
                data={practicas}
              />
            )
        }
      </IonContent>
      <IonModal
        isOpen={showFiltros}
        swipeToClose={true}
      >
        {
          filtros && (
            <PracticasFiltros
              filtros={filtros}
              onDismissModal={onCloseFiltros}
            />
          )
        }
      </IonModal>
    </IonPage>
  );
};

export default Estadisticas;
