import React, { useContext, useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonPage, IonIcon, IonRadioGroup, IonRadio, IonSearchbar, IonSpinner, IonChip } from '@ionic/react';
import './SelectorDeResidente.scss'
import { alertCircleOutline, checkmarkOutline, closeOutline, trashOutline } from 'ionicons/icons';
import { UsuarioSimple } from '../models/UsuarioSimple';
import { AppContext } from '../AppContext';
import axios from 'axios';
import Log from './Log';

interface SelectorDeResidente {
  onDismissModal: (residente: UsuarioSimple | null) => void;
}

// https://blog.logrocket.com/building-type-safe-dictionary-typescript/
let diccionario: { [key: number]: UsuarioSimple } = {};

const SelectorDeResidente: React.FC<SelectorDeResidente> = ({ onDismissModal }) => {

  const [residente, setResidente] = useState<number | undefined>(undefined);
  const [lista, setLista] = useState<UsuarioSimple[]>([]);
  const [filtro, setFiltro] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const {
    usuario
  } = useContext(AppContext);
  
  useEffect(() => {
    diccionario = {};
  }, []);

  useEffect(() => {
    if (filtro) {
      setError("");
      setLoader(true);
      const url = `${process.env.REACT_APP_API_URL}/usuarios/buscar?texto=${filtro}`;
      axios.get(url)
      .then((res) => {
        const items = res.data.data;
        items.forEach((x: UsuarioSimple) => {
          diccionario[x.id] = x;
        });
        console.dir(diccionario);
        setLista(res.data.data);
        setLoader(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `SelectorDeResidente.tsx » filtro(): Error al buscar a usuarios con el filtro '${filtro}'`
        );
        setLoader(false);
        setError(error);
      });
    } else {
      setLista([]);
    }
  // eslint-disable-next-line
  }, [filtro]);

  const guardar = () => {
    if (residente && residente in diccionario) {
      const seleccion = diccionario[residente];
      onDismissModal(seleccion);
    } else {
      onDismissModal(null);
    }
  }

  const limpiar = () => {
    onDismissModal(null);
  }

  const ListaDeUsuarios = () => {
    return (
      <IonList mode="md" className="ion-no-padding">
        <IonRadioGroup
          value={residente}
          onIonChange={e => setResidente(e.detail.value)}
        >
          {
            lista.map((usuario: UsuarioSimple) => (
              <IonItem key={usuario.id}>
                <IonLabel>{usuario.nombre_completo}</IonLabel>
                <IonRadio value={usuario.id} />
              </IonItem>
            ))
          }
        </IonRadioGroup>
      </IonList>
    )
  }

  return (
    <IonPage id="selector-residente">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonTitle>
            Seleccionar Residente
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismissModal(null)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <IonSearchbar
          mode="ios"
          value={filtro}
          placeholder="Filtrar Residentes..."
          onIonChange={e => setFiltro(e.detail.value!)}
          showClearButton="always"
          debounce={500}
        >
        </IonSearchbar>
        {
          loader
          ? <IonSpinner name="crescent" color="secondary" />
          : (
            error
            ? (
              <IonChip color="danger" onClick={() => setError("")}>
                <IonIcon icon={alertCircleOutline} color="primary" />
                <IonLabel>{error}</IonLabel>
                <IonIcon icon={closeOutline} />
              </IonChip>
            )
            : <ListaDeUsuarios />
          )
        }        
      </IonContent>
      <IonFooter>
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonButton onClick={limpiar}>
              <IonIcon icon={trashOutline} />
              &nbsp;Limpiar
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton mode="md" fill="solid" color="success" onClick={guardar}>
              Guardar
              <IonIcon slot="end" icon={checkmarkOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default SelectorDeResidente;
