import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonCard, IonCardHeader, IonCardContent, IonIcon, IonFooter, IonCardTitle, IonCardSubtitle, IonGrid, IonLoading, IonProgressBar, IonNote, IonModal } from '@ionic/react';
import { closeOutline, chevronBackOutline, chevronForwardOutline, checkmarkCircleOutline, checkmarkOutline, logInOutline } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import './Registro.scss';
import { PASSWORD_LARGO } from '../constantes';
import SelectorDeInstitucion from '../components/SelectorDeInstitucion';
import { Institucion } from '../models/Institucion';
import Log from '../components/Log';

interface RegistroProps {
  dismissModal: () => void;
  openRecupero: () => void;
}

const Registro: React.FC<RegistroProps> = ({ dismissModal, openRecupero }) => {

  const [pagina, setPagina] = useState(1);
  const [dni, setDni] = useState('');
  const [error, setError] = useState('');
  const [dniError, setDniError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState<{ id?: number; nombre_completo?: string; } | null>(null);
  const [cliente, setCliente] = useState<{
    ClienteID?: number;
    Nombre?: string;
    Email?: string;
    FechaDeAlta?: string;
    FechaDeBaja?: string;
    FechaDeNacimiento?: string;
    IdCategoria?: string;
    Categoria?: string;
    Inactivo: boolean;
  } | null>(null);
  const [inexistente, setInexistente] = useState(false);
  const [editarResidencia, setEditarResidencia] = useState(false);
  const [residencia, setResidencia] = useState<Institucion | undefined>(undefined);

  const validar = async (e: React.FormEvent) => {
    e.preventDefault();
    setCliente(null);
    setUsuario(null);
    setInexistente(false);
    if (!dni) {
      setDniError(!dni);
    } else {
      setDniError(false);
    }
    if (dni) {
      setLoading(true);
      setDniError(false);
      const url = `${process.env.REACT_APP_API_URL}/usuarios/validar/${dni}`;
      axios.get(url)
        .then(async (res) => {
          const estado = res.data.estado;
          if (estado === "no registrado") {
            const cliente = res.data.cliente;
            if (cliente) {
              cliente.Inactivo = !cliente.Activo;
              setCliente(cliente);
              if (!cliente.Inactivo) {
                setPagina(2);
              }
            }
          } else if (estado === "registrado") {
            const user = res.data.usuario;
            if (user) {
              setUsuario(user);
            }
          } else {
            setUsuario(null);
            setInexistente(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          Log.error(
            err,
            undefined,
            `Registro.tsx » validar(): Error al intentar validar el DNI ${dni}`
          );
          setDniError(true);
          setLoading(false);
        });
    }
  };

  const onSubmit = (data: any) => {
    const registro = {
      id: dni,
      id_cliente: cliente?.ClienteID,
      nombre_completo: cliente?.Nombre,
      nombre: data.nombre,
      email: cliente?.Email,
      password: data.password1,
      password_confirmation: data.password2,
      fecha_nacimiento: cliente?.FechaDeNacimiento,
      fecha_ingreso: cliente?.FechaDeAlta,
      id_clasificacion: cliente?.IdCategoria,
      clasificacion: cliente?.Categoria,
      id_institucion: residencia?.id
    };
    setError("");
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/auth/register`;
    axios.post(url, registro)
      .then(async () => {
        setLoading(false);
        setPagina(3);
      })
      .catch((err) => {
        Log.error(
          err,
          undefined,
          `Registro.tsx » onSubmit(): Error al intentar registrar al residente con los datos: ${JSON.stringify(registro)}`
        );        
        setError(`Error al intentar registrar al residente`);
        setLoading(false);
      });
  };

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      nombre: "",
      password1: "",
      password2: "",
      id_residencia: ""
    }
  });

  const guardarInstitucion = (residencia: Institucion) => {
    setEditarResidencia(false);
    if (residencia) {
      setValue("id_residencia", residencia.id.toString(), { shouldValidate: true });
      setResidencia(residencia);
    } else {
      setValue("id_residencia", "", { shouldValidate: true });
      setResidencia(undefined);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage id="registro-page">
        <IonHeader translucent={true} no-border>
          <IonToolbar color="tertiary">
            <IonButtons slot="end">
              <IonButton onClick={dismissModal}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>
              Registro de Residentes
            </IonTitle>
          </IonToolbar>
          <IonToolbar color="secondary">
            <IonTitle>Paso {pagina} de 3</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonProgressBar value={pagina === 1 ? 0.33 : (pagina === 2 ? 0.66 : 1)}></IonProgressBar>
          {
            pagina === 1 && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #ddd" }}>
                <IonCardHeader>
                  <IonCardTitle>
                    Ingrese su DNI
                  </IonCardTitle>
                  <IonCardSubtitle>
                    Este paso verificará su DNI en la plataforma de AAOT
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                    <IonItem className={dniError ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">DNI</IonLabel>
                      <IonInput
                        name="dni"
                        type="number"
                        value={dni}
                        spellCheck={false}
                        autocapitalize="off"
                        onIonChange={e => {
                          setCliente(null);
                          setUsuario(null);
                          setDni(e.detail.value!);
                          setDniError(e.detail.value!.trim() === "");
                        }}
                        required>
                      </IonInput>
                      {dniError && (
                        <IonNote slot="error">Debe indicar su número de DNI</IonNote>
                      )}
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            )
          }
          {
            pagina === 1 && usuario && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #ddd" }}>
                <IonCardHeader>
                  <IonCardTitle>
                    Usuario ya registrado
                    <IonIcon icon={checkmarkCircleOutline} slot="end" />
                  </IonCardTitle>
                  <IonCardSubtitle>
                    Este DNI ya fue registrado en la aplicación a nombre de {usuario.nombre_completo}
                  </IonCardSubtitle>
                  <IonIcon icon={checkmarkCircleOutline} slot="end" />
                </IonCardHeader>
                <IonCardContent>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonButton expand="block" color="success" mode="ios" onClick={dismissModal}>
                          Ingresar
                          <IonIcon slot="end" icon={chevronForwardOutline} />
                        </IonButton>
                      </IonCol>
                      <IonCol size="12">
                        <IonButton expand="block" color="success" mode="ios"
                          onClick={() => {
                            dismissModal();
                            openRecupero();
                          }}
                        >
                          Recuperar contraseña
                          <IonIcon slot="end" icon={chevronForwardOutline} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            )
          }
          {
            pagina === 1 && inexistente && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                <IonCardHeader>
                  <IonCardTitle>
                    Usuario inexistente
                  </IonCardTitle>
                </IonCardHeader>
                <IonItem className="ion-activated">
                  <IonLabel>Por favor, contacte con Administración</IonLabel>
                </IonItem>
              </IonCard>
            )
          }
          {
            pagina === 1 && cliente?.Inactivo && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                <IonCardHeader>
                  <IonCardTitle>
                    Usuario inactivo
                  </IonCardTitle>
                  <IonCardSubtitle>
                    {cliente?.Nombre}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>Por favor, contacte con Administración</IonLabel>
                </IonItem>
              </IonCard>
            )
          }
          {
            pagina === 2 && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #ddd" }}>
                <IonCardHeader>
                  <IonCardTitle>
                    Bienvenido<br />{cliente?.Nombre}
                  </IonCardTitle>
                  <IonCardSubtitle>
                    Por favor, complete los siguientes campos
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                    <IonItem className={errors.nombre ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">Nombre de pila o Alias</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            autocomplete="off"
                            onIonBlur={() => field.onBlur()}
                            onIonChange={(e) => field.onChange(e.detail.value)}
                            placeholder="Ingrese su Nombre de pila o Alias"
                          />
                        )}
                        control={control}
                        name="nombre"
                        rules={{
                          required: {
                            value: true,
                            message: "Campo requerido"
                          }
                        }}
                      />
                      <IonNote slot="error">{errors?.nombre?.message}</IonNote>
                    </IonItem>
                    <IonItem className={errors.password1 ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">Nueva contraseña</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            autocomplete="off"
                            onIonBlur={() => field.onBlur()}
                            onIonChange={(e) => field.onChange(e.detail.value)}
                            placeholder="Ingrese su nueva Contraseña"
                          />
                        )}
                        control={control}
                        name="password1"
                        rules={{
                          required: {
                            value: true,
                            message: "Campo requerido"
                          },
                          minLength: {
                            value: PASSWORD_LARGO,
                            message: `La Contraseña debe poseer al menos ${PASSWORD_LARGO} caracteres`
                          }
                        }}
                      />
                      <IonNote slot="error">{errors?.password1?.message}</IonNote>
                    </IonItem>
                    <IonItem className={errors.password2 ? "ion-invalid" : ""}>
                      <IonLabel position="stacked">Repetir nueva contraseña</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            autocomplete="off"
                            onIonBlur={() => field.onBlur()}
                            onIonChange={(e) => field.onChange(e.detail.value)}
                            placeholder="Repita la nueva Contraseña"
                          />
                        )}
                        control={control}
                        name="password2"
                        rules={{
                          required: {
                            value: true,
                            message: "Campo requerido"
                          },
                          minLength: {
                            value: PASSWORD_LARGO,
                            message: `La Contraseña debe poseer al menos ${PASSWORD_LARGO} caracteres`
                          },
                          validate: (value) => {
                            const { password1 } = getValues();
                            return password1 === value || "Las Contraseñas no coinciden";
                          }
                        }}
                      />
                      <IonNote slot="error">{errors?.password2?.message}</IonNote>
                    </IonItem>
                    <IonItem className={errors.id_residencia ? "ion-invalid" : ""}>
                      <IonLabel>
                        <h2 className={errors.id_residencia ? "invalido" : ""}>Residencia de Origen</h2>
                        <p className={errors.id_residencia ? "invalido" : "gris"}>{
                          residencia
                            ? residencia.nombre
                            : '(NO SELECCIONADA)'
                        }</p>
                        {
                          residencia && (
                            <small className="gris">{residencia.provincia}</small>
                          )
                        }
                      </IonLabel>
                      <Controller
                        render={(field) => (
                          <IonInput
                            {...field}                            
                            autocomplete="off"
                            style={{ display: "none" }}
                          />
                        )}
                        control={control}
                        name="id_residencia"
                        rules={{
                          required: {
                            value: true,
                            message: "Campo requerido"
                          }
                        }}
                      />
                      <IonButton fill="outline" slot="end" onClick={() => setEditarResidencia(true)}>
                        Editar
                      </IonButton>
                      <IonNote slot="error">{errors?.id_residencia?.message}</IonNote>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            )
          }
          {
            pagina === 2 && error && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #d50000" }} color="danger">
                <IonCardHeader>
                  <IonCardTitle>
                    Error al intentar el Registo
                  </IonCardTitle>
                  <IonCardSubtitle>
                    Si el problema subsiste, contacte con Administración
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>Por favor, intente nuevamente</IonLabel>
                </IonItem>
              </IonCard>
            )
          }
          {
            pagina === 3 && (
              <IonCard style={{ boxShadow: "none", border: "1px solid #009624" }} color="success">
                <IonCardHeader>
                  <IonCardTitle>
                    Usuario creado
                  </IonCardTitle>
                  <IonCardSubtitle>
                    {cliente?.Nombre}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>Ya puede ingresar a la aplicación</IonLabel>
                </IonItem>
              </IonCard>
            )
          }
          <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={pagina === 1 ? "Verificando..." : (pagina === 2 ? "Guardando..." : "Ingresar")}
            duration={60000}
          />
        </IonContent>
        <IonFooter translucent className="ion-no-border">
          <IonToolbar color="tertiary">
            <IonButtons slot="start">
              {
                pagina === 2 && (
                  <IonButton onClick={() => setPagina(1)}>
                    <IonIcon slot="start" icon={chevronBackOutline} />
                    Atrás
                  </IonButton>
                )
              }
            </IonButtons>
            <IonButtons slot="end">
              {
                pagina === 1 && (
                  <IonButton onClick={validar}>
                    Verificar
                    <IonIcon slot="end" icon={chevronForwardOutline} />
                  </IonButton>
                )
              }
              {
                pagina === 2 && (
                  <IonButton color="success" type="submit">
                    Guardar
                    <IonIcon slot="end" icon={checkmarkOutline} />
                  </IonButton>
                )
              }
              {
                pagina === 3 && (
                  <IonButton color="success" onClick={dismissModal}>
                    Ingresar
                    <IonIcon slot="end" icon={logInOutline} />
                  </IonButton>
                )
              }
            </IonButtons>
          </IonToolbar>
        </IonFooter>
        <IonModal
          isOpen={editarResidencia}
          swipeToClose={true}
        >
          <SelectorDeInstitucion
            onDismissModal={guardarInstitucion}
            onClose={() => setEditarResidencia(false)}
          />
        </IonModal>
      </IonPage>
    </form>
  );
};

export default Registro;
