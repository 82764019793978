import React, { useContext, useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonList, IonItem, IonAlert, IonLabel, IonButton, IonToggle, IonModal, IonLoading, IonChip, IonIcon, IonText } from '@ionic/react';
import { AppContext } from '../AppContext';
import SelectorDeInstitucion from '../components/SelectorDeInstitucion';
import './Cuenta.scss';
import Foto from '../components/Foto';
import axios from 'axios';
import { alertCircleOutline, closeOutline } from 'ionicons/icons';
import { differenceInCalendarYears, parse } from 'date-fns';
import { Institucion } from '../models/Institucion';
import LogoAaot from '../components/LogoAaot';
import { PASSWORD_LARGO } from '../constantes';
import { version } from '../../package.json';
import Log from '../components/Log';

const Cuenta: React.FC = () => {

  const {
    esquema,
    usuario,
    setUsuario,
    autoAvanzar,
    setAutoAvanzar
  } = useContext(AppContext);
  const [error, setError] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [editarNombre, setEditarNombre] = useState(false);
  const [editarFoto, setEditarFoto] = useState(false);
  const [editarResidencia, setEditarResidencia] = useState(false);
  const [residenciaNombre, setResidenciaNombre] = useState("");
  const [residenciaProvincia, setResidenciaProvincia] = useState("");
  const [editarPassword, setEditarPassword] = useState(false);
  const [editarNotas, setEditarNotas] = useState(false);

  useEffect(() => {
    if (esquema && usuario) {
      if (usuario.id_institucion) {
        const residencia: Institucion = esquema.instituciones.find((x: any) => x.id === usuario?.id_institucion);
        if (residencia) {
          setResidenciaNombre(residencia.nombre);
          setResidenciaProvincia(residencia.provincia);
        }
      } else {
        setResidenciaNombre("");
        setResidenciaProvincia("");
      }
    }
  }, [esquema, usuario]);

  const convertBlobToFile = async (url: any, fileName: string) => {
    let blob = await fetch(url).then(r => r.blob());
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  }

  const guardarFoto = async (foto: any) => {
    setEditarFoto(false);
    if (usuario && foto) {
      setError("");
      setLoading("Guardando foto...");
      const name = `${usuario.id}.jpg`;
      const file = await convertBlobToFile(foto, name);
      const id_user = usuario.id?.toString() || "";
      let formData = new FormData();
      formData.append("id", id_user);
      formData.append("foto", file, name);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/usuarios/foto`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(async (data) => {
          const clon = Object.assign({}, usuario);
          // No refresca la imagen ya que no cambia su URL
          // clon.foto = data.data.foto;
          clon.foto = `${data.data.foto}?x=${Date.now()}`;
          setUsuario(clon);
          setLoading("");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Cuenta.tsx » guardarFoto(): Error al intentar subir una Foto del Usuario ${id_user}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  const guardarNombre = (nombre: string) => {
    if (usuario && nombre) {
      setError("");
      setLoading("Guardando nombre...");
      axios.patch(
        `${process.env.REACT_APP_API_URL}/usuarios/${usuario.id}`, { "nombre": nombre })
        .then(async () => {
          const clon = Object.assign({}, usuario);
          clon.nombre = nombre;
          setUsuario(clon);
          setLoading("");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Cuentas.tsx » guardarNombre(): Error al intentar editar el nombre (${nombre}) del Usuario ${usuario.id}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  const calcularEdad = () => {
    if (usuario?.fecha_nacimiento) {
      const fechaNacimiento = parse(usuario.fecha_nacimiento, "yyyy-MM-dd", new Date());
      let edad: number = differenceInCalendarYears(new Date(), fechaNacimiento);
      return <IonText color="medium"><small>{edad} años</small></IonText>;
    }
    return null;
  };

  const guardarInstitucion = (residencia: Institucion) => {
    console.log(residencia);
    setEditarResidencia(false);
    if (usuario) {
      setError("");
      setLoading("Guardando Residencia...");
      axios.patch(
        `${process.env.REACT_APP_API_URL}/usuarios/${usuario.id}`, { "id_institucion": residencia ? residencia.id : null })
        .then(async () => {
          const clon = Object.assign({}, usuario);
          clon.id_institucion = residencia ? residencia.id : undefined;
          clon.residencia = residencia ? residencia.nombre : undefined;
          clon.provincia = residencia ? residencia.provincia : undefined;
          setUsuario(clon);
          setLoading("");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Cuentas.tsx » guardarInstitucion(): Error al intentar guardar la Residencia (${JSON.stringify(residencia)}) del Usuario ${usuario.id}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  const guardarPassword = (password: string) => {
    if (usuario && password) {
      setError("");
      setLoading("Guardando contraseña...");
      axios.patch(
        `${process.env.REACT_APP_API_URL}/usuarios/${usuario.id}`, { "password": password })
        .then(async () => {
          setLoading("");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Cuentas.tsx » guardarPassword(): Error al intentar guardar la contraseña (${password}) del Usuario ${usuario.id}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  const guardarNotas = (notas: string) => {
    if (usuario) {
      setError("");
      setLoading("Guardando notas...");
      axios.patch(
        `${process.env.REACT_APP_API_URL}/usuarios/${usuario.id}`, { "leyenda": notas })
        .then(async () => {
          const clon = Object.assign({}, usuario);
          clon.leyenda = notas;
          setUsuario(clon);
          setLoading("");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Cuentas.tsx » guardarNotas(): Error al intentar guardar las notas del Usuario ${usuario.id}. Notas: "${notas}"`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  return (
    <IonPage id="cuenta-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Cuenta</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {
          error && (
            <IonChip color="danger" onClick={() => setError("")}>
              <IonIcon icon={alertCircleOutline} color="primary" />
              <IonLabel>{error}</IonLabel>
              <IonIcon icon={closeOutline} />
            </IonChip>
          )
        }
        {usuario?.nombre &&
          (<div className="ion-padding-top ion-text-center">
            <img
              src={
                usuario?.foto
                  ? `${process.env.REACT_APP_API_FOTOS}/${usuario.foto}`
                  : "assets/img/usuario.png"
              }
              alt="avatar"
              onClick={() => setEditarFoto(true)}
            />
            <h2 style={{ marginBottom: 0 }}>{usuario?.nombre}</h2>
            {calcularEdad()}
            <IonList>
              <IonItem>
                <IonLabel>
                  <h2>Foto</h2>
                </IonLabel>
                <IonButton fill="outline" slot="end" onClick={() => setEditarFoto(true)}>
                  Editar
                </IonButton>
              </IonItem>
              <IonItem>
                <IonLabel>
                  <h2>Nombre</h2>
                  <p>{usuario?.nombre}</p>
                </IonLabel>
                <IonButton fill="outline" slot="end" onClick={() => setEditarNombre(true)}>
                  Editar
                </IonButton>
              </IonItem>
              {/* {
                !usuario?.administrador && ( */}
              <IonItem>
                <IonLabel>
                  <h2>Residencia de Origen</h2>
                  <p className="gris">{
                    residenciaNombre
                      ? residenciaNombre
                      : '(NO SELECCIONADA)'
                  }</p>
                  {
                    residenciaProvincia && (
                      <small className="gris">{residenciaProvincia}</small>
                    )
                  }
                </IonLabel>
                <IonButton fill="outline" slot="end" onClick={() => setEditarResidencia(true)}>
                  Editar
                </IonButton>
              </IonItem>
                {/* )
              } */}
              <IonItem>
                Contraseña
                <IonButton fill="outline" slot="end" onClick={() => setEditarPassword(true)}>
                  Editar
                </IonButton>
              </IonItem>
              {
                !usuario?.administrador && (
                  <>
                    <IonItem lines="none">
                      Notas
                      <IonButton fill="outline" slot="end" onClick={() => setEditarNotas(true)}>
                        Editar
                      </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                      <p className="notas">
                        {usuario?.leyenda}
                      </p>
                    </IonItem>
                  </>
                )
              }
              <IonItem>
                <IonLabel>Avanzar automáticamente</IonLabel>
                <IonToggle checked={autoAvanzar} onIonChange={e => setAutoAvanzar(e.detail.checked)} />
              </IonItem>
            </IonList>
            <IonItem>
              <IonLabel>Versión</IonLabel>
              <IonLabel color="primary" slot="end">{version}</IonLabel>
            </IonItem>
          </div>)
        }
        <IonLoading
          isOpen={loading !== ""}
          onDidDismiss={() => setLoading("")}
          message={loading}
          duration={60000}
        />
      </IonContent>
      <IonModal
        isOpen={editarFoto}
        swipeToClose={true}
      >
        <Foto
          onDismissModal={guardarFoto}
        />
      </IonModal>
      <IonModal
        isOpen={editarResidencia}
        swipeToClose={true}
      >
        <SelectorDeInstitucion
          onDismissModal={guardarInstitucion}
          onClose={() => setEditarResidencia(false)}
        />
      </IonModal>
      <IonAlert
        isOpen={editarNombre}
        header="Cambiar Nombre"
        buttons={[
          'Cancelar',
          {
            text: 'Guardar',
            handler: (data) => {
              guardarNombre(data.nombre);
            }
          }
        ]}
        inputs={[
          {
            type: 'text',
            name: 'nombre',
            value: usuario?.nombre,
            placeholder: 'Ingrese el Nombre'
          }
        ]}
        onDidDismiss={() => setEditarNombre(false)}
      />
      <IonAlert
        isOpen={editarPassword}
        header="Cambiar contraseña"
        subHeader={`Al menos ${PASSWORD_LARGO} caracteres`}
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Guardar',
            handler: (data) => {
              const pass: string = data.password;
              if (pass !== data.password2) {
                setErrorPassword("Las contraseñas no coinciden.");
                return false;
              }
              if (pass.length < PASSWORD_LARGO) {
                setErrorPassword(`La nueva contraseña debe poseer al menos ${PASSWORD_LARGO} caracteres`);
                return false;
              }
              guardarPassword(data.password);
            }
          }
        ]}
        inputs={[
          {
            label: 'Contraseña',
            type: 'password',
            name: 'password',
            placeholder: 'Ingrese la Contraseña',
            attributes: {
              minLength: PASSWORD_LARGO,
              maxlength: 100
            }
          },
          {
            label: 'Verificación',
            type: 'password',
            name: 'password2',
            placeholder: 'Repite la Contraseña',
            attributes: {
              minLength: PASSWORD_LARGO,
              maxlength: 100
            }
          }
        ]}
        onDidDismiss={() => setEditarPassword(false)}
      />
      <IonAlert
        cssClass='alerta-ancha'
        isOpen={editarNotas}
        header="Cambiar Notas"
        buttons={[
          'Cancelar',
          {
            text: 'Guardar',
            handler: (data) => {
              guardarNotas(data.notas);
            }
          }
        ]}
        inputs={[
          {
            type: 'textarea',
            name: 'notas',
            value: usuario?.leyenda,
            placeholder: 'Ingrese sus notas de perfil',
            attributes: {
              rows: 30,
              maxLength: 10000
            },
          }
        ]}
        onDidDismiss={() => setEditarNotas(false)}
      />
      <IonAlert
        isOpen={errorPassword !== ""}
        onDidDismiss={() => setErrorPassword("")}
        header={'Contraseña'}
        message={errorPassword}
        buttons={['Reintentar']}
      />
    </IonPage>
  );
};

export default Cuenta;
