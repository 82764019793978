import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "./AppContext";

// https://stackoverflow.com/questions/66193574/route-guard-while-using-ionic-react

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const {
    isLogged
  } = useContext(AppContext);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogged() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
