import React, { useState, useContext, useEffect, Fragment } from 'react';
import { AppContext } from '../AppContext';
import { IonRadioGroup, IonRadio, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonListHeader, IonItem, IonLabel, IonFooter, IonIcon, IonProgressBar, IonText, IonCheckbox, IonItemDivider, IonSearchbar } from '@ionic/react';
import { checkmarkOutline, chevronBackOutline, chevronForwardOutline, closeOutline } from 'ionicons/icons';
import { Tratamiento } from '../models/Tratamiento';
import { ModuloSimple } from '../models/ModuloSimple';
import { Modulo } from '../models/Modulo';
import { Submodulo } from '../models/Submodulo';
import { Localizacion } from '../models/Localizacion';
import { Via } from '../models/Via';
import { Etiologia } from '../models/Etiologia';
import { Diagnostico } from '../models/Diagnostico';
import './NuevoModulo.css'

interface Item {
  id: number,
  id_modulo: string,
  nombre: string,
  checked: boolean
};

interface NuevoModuloProps {
  moduloActual: Modulo | undefined;
  onDismissModal: (nuevoModulo: Modulo | undefined) => void;
}

const NuevoModuloPage: React.FC<NuevoModuloProps> = ({ moduloActual, onDismissModal }) => {

  const PAGINA_MODULOS = 1;
  const PAGINA_SUBMODULOS = 2;
  const PAGINA_ETIOLOGIAS = 3;
  const PAGINA_DIAGNOSTICOS = 4;
  const PAGINA_LOCALIZACIONES = 5;
  const PAGINA_VIAS = 6;
  const PAGINA_TRATAMIENTOS = 7;
  const PAGINA_RESUMEN = 8;

  const {
    esquema,
    autoAvanzar
  } = useContext(AppContext);
  const [pagina, setPagina] = useState(1);
  const [modulo, setModulo] = useState<string | null>(moduloActual?.id_modulo || null);
  const [submodulo, setSubmodulo] = useState<number | null>(moduloActual?.id_submodulo || null);
  const [etiologia, setEtiologia] = useState<number | null>(moduloActual?.id_etiologia || null);
  const [diagnostico, setDiagnostico] = useState<number | null>(moduloActual?.id_diagnostico || null);
  const [localizaciones, setLocalizaciones] = useState<Item[]>([]);
  const [via, setVia] = useState<number | null>(moduloActual?.id_via || null);
  const [tratamiento, setTratamiento] = useState<string | null>(moduloActual?.id_tratamiento || null);
  const [tratamientoNiveles, setTratamientoNiveles] = useState<string[]>([]);
  const [tratamientoFiltro, setTratamientoFiltro] = useState("");

  useEffect(() => {
    setLocalizaciones(esquema.localizaciones.map((x: any) => (
      {
        id: x.id,
        id_modulo: x.id_modulo,
        nombre: x.nombre,
        checked: false
      }
    )));
    setTratamientoNiveles([...Array.from(
      new Set<string>(esquema.tratamientos.map((x: Tratamiento) => x.nivel))
    )]);
  }, [esquema]);

  // useEffect(() => {
  //   setSubmodulo(null);
  //   setEtiologia(null);
  //   setDiagnostico(null);
  //   localizaciones.forEach((x: Item) => x.checked = false);
  //   setVia(null);
  //   setTratamiento(null);
  //   // https://github.com/facebook/create-react-app/issues/6880
  //   // eslint-disable-next-line react-hooks/exhaustive-deps    
  // }, [modulo]);

  // useEffect(() => {
  //   setEtiologia(null);
  //   setDiagnostico(null);
  // }, [submodulo]);

  // useEffect(() => {
  //   setDiagnostico(null);
  // }, [etiologia]);

  const armarNivelDeTratamiento = (nivel: string) => {
    let regexp = new RegExp(tratamientoFiltro, "i");
    const tratamientos = tratamientoFiltro
      ? esquema.tratamientos.filter(
        (x: Tratamiento) => x.id_modulo === modulo && x.nivel === nivel && x.nombre.match(regexp)
      )
      : esquema.tratamientos.filter(
        (x: Tratamiento) => x.id_modulo === modulo && x.nivel === nivel
      );
    if (!tratamientos.length) {
      return null;
    }
    return (
      <Fragment key={nivel}>
        <IonItemDivider color="medium" sticky>
          COMPLEJIDAD {parseInt(nivel)} PRÁCTICA
        </IonItemDivider>
        {
          tratamientos.length
            ? (
              tratamientos.map((x: Tratamiento) => (
                <IonItem key={x.id}>
                  <IonLabel text-wrap>{x.nombre}</IonLabel>
                  <IonRadio slot="end" value={x.id} />
                </IonItem>
              )
              )) : (
              <IonItem color="light">
                <IonLabel>(Sin Tratamientos)</IonLabel>
              </IonItem>
            )
        }
      </Fragment>
    );
  };

  const validarCampo = (id: string) => {
    switch (id) {
      case "modulo":
        return modulo
          ? <IonLabel text-wrap className="ion-text-end"><IonText color="success"><h4>{esquema.modulos.find((x: any) => x.id === modulo).nombre}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(PAGINA_MODULOS)}>COMPLETAR</IonButton>;
      case "submodulo":
        return submodulo
          ? <IonLabel text-wrap className="ion-text-end"><IonText color="success"><h4>{esquema.submodulos.find((x: any) => x.id === submodulo).nombre}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(PAGINA_SUBMODULOS)}>COMPLETAR</IonButton>;
      case "etiologia":
        return etiologia
          ? <IonLabel text-wrap className="ion-text-end"><IonText color="success"><h4>{esquema.etiologias.find((x: any) => x.id === etiologia).nombre}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(PAGINA_ETIOLOGIAS)}>COMPLETAR</IonButton>;
      case "diagnostico":
        return diagnostico
          ? <IonLabel text-wrap className="ion-text-end"><IonText color="success"><h4>{esquema.diagnosticos.find((x: any) => x.id === diagnostico).nombre}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(PAGINA_DIAGNOSTICOS)}>COMPLETAR</IonButton>;
      case "localizacion":
        const items = localizaciones.filter((x: Item) => x.checked);
        return items.length > 0
          ? <IonLabel text-wrap className="ion-text-end"><IonText color="success"><h4>{items.map((x: Item) => x.id).map((loc: number) => esquema.localizaciones.find((x: any) => x.id === loc).nombre).join(', ')}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(PAGINA_LOCALIZACIONES)}>COMPLETAR</IonButton>;
      case "via":
        return via
          ? <IonLabel text-wrap className="ion-text-end"><IonText color="success"><h4>{esquema.vias.find((x: any) => x.id === via).nombre}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(PAGINA_VIAS)}>COMPLETAR</IonButton>;
      case "tratamiento":
        return tratamiento
          ? <IonLabel text-wrap className="ion-text-end"><IonText color="success"><h4>{esquema.tratamientos.find((x: any) => x.id === tratamiento).nombre}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(PAGINA_TRATAMIENTOS)}>COMPLETAR</IonButton>;
      default:
        break;
    }
  };

  const chequearSubmodulos = (mod: string | null, setearSubmodulo: boolean = true) => {
    const submodulos = esquema.submodulos.filter((x: Submodulo) => x.id_modulo === mod);
    if (submodulos.length === 1) {
      if (setearSubmodulo) {
        setSubmodulo(submodulos[0].id);
      }
      return false;
    }
    return true;
  };

  const chequearLocalizaciones = (mod: string | null) => {
    return esquema.localizaciones.filter((x: Localizacion) => x.id_modulo === mod).length > 0;
  };

  const chequearVias = (mod: string | null) => {
    return esquema.vias.filter((x: Via) => x.id_modulo === mod).length > 0;
  };

  const avanzar = () => {
    let delta: number = 0;
    if (pagina === PAGINA_MODULOS) {
      if (chequearSubmodulos(modulo) === false) {
        delta++;
      }
    }
    if (pagina === PAGINA_DIAGNOSTICOS || pagina === PAGINA_LOCALIZACIONES) {
      if (pagina === PAGINA_DIAGNOSTICOS) {
        if (chequearLocalizaciones(modulo) === false) {
          delta++;
        }
      }
      if (chequearVias(modulo) === false) {
        delta++;
      }
    }
    let nuevaPagina: number = pagina + 1 + delta;
    if (nuevaPagina > PAGINA_RESUMEN) {
      nuevaPagina = PAGINA_RESUMEN;
    }
    setPagina(nuevaPagina);
  };

  const retroceder = () => {
    let delta: number = 0;
    if (pagina === PAGINA_ETIOLOGIAS) {
      if (chequearSubmodulos(modulo) === false) {
        delta++;
      }
    }
    if (pagina === PAGINA_VIAS || pagina === PAGINA_TRATAMIENTOS) {
      if (pagina === PAGINA_TRATAMIENTOS) {
        if (chequearVias(modulo) === false) {
          delta++;
        }
      }
      if (chequearLocalizaciones(modulo) === false) {
        delta++;
      }
    }
    let nuevaPagina: number = pagina - 1 - delta;
    if (nuevaPagina < 1) {
      nuevaPagina = 1;
    }
    setPagina(nuevaPagina);
  };

  const validarAvance = () => {
    if (pagina === PAGINA_MODULOS) {
      return modulo !== null;
    } else if (pagina === PAGINA_SUBMODULOS) {
      return submodulo !== null;
    } else if (pagina === PAGINA_ETIOLOGIAS) {
      return etiologia !== null;
    } else if (pagina === PAGINA_DIAGNOSTICOS) {
      return diagnostico !== null;
    } else if (pagina === PAGINA_LOCALIZACIONES) {
      return localizaciones.filter((x: Item) => x.checked).length > 0;
    } else if (pagina === PAGINA_VIAS) {
      return via !== null;
    } else if (pagina === PAGINA_TRATAMIENTOS) {
      return tratamiento !== null;
    }
    return true;
  };

  const guardar = () => {
    // TODO Validaciones!
    const items = localizaciones.filter((x: Item) => x.checked);
    const nuevoModulo: Modulo = {
      id: moduloActual
        ? moduloActual.id
        : `${modulo}-${Math.random().toString().substring(2, 8)}`,
      id_modulo: modulo,
      modulo: esquema.modulos.find((x: any) => x.id === modulo).nombre,
      id_submodulo: submodulo,
      submodulo: esquema.submodulos.find((x: any) => x.id === submodulo).nombre,
      id_etiologia: etiologia,
      etiologia: esquema.etiologias.find((x: any) => x.id === etiologia).nombre,
      id_diagnostico: diagnostico,
      diagnostico: esquema.diagnosticos.find((x: any) => x.id === diagnostico).nombre,
      id_localizacion: items.length ? items.map((x: Item) => x.id) : null,
      localizacion: items.length ? items.map((x: Item) => x.id).map((loc: number) => esquema.localizaciones.find((x: any) => x.id === loc).nombre).join(', ') : null,
      id_localizaciones: items.length ? items.map((x: Item) => `[${x.id}]`).join(',') : null,
      id_via: via,
      via: via ? esquema.vias.find((x: any) => x.id === via).nombre : null,
      id_tratamiento: tratamiento,
      tratamiento: esquema.tratamientos.find((x: any) => x.id === tratamiento).nombre
    };
    onDismissModal(nuevoModulo);
  }

  return (
    <IonPage id="nuevo-modulo">
      <IonHeader translucent={false}>
        <IonToolbar color="primary">
          <IonTitle>
            {moduloActual ? "Editar" : "Nuevo"}&nbsp;Módulo
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismissModal(undefined)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonProgressBar color="secondary" value={pagina / PAGINA_RESUMEN}></IonProgressBar>
        {
          pagina === PAGINA_MODULOS && (
            <IonList lines="full">
              <IonRadioGroup value={modulo} onIonChange={e => {
                setModulo(e.detail.value);
                if (autoAvanzar) { avanzar(); }
              }}
              >
                <IonListHeader lines="full" color='tertiary'>
                  <IonLabel>MÓDULO</IonLabel>
                </IonListHeader>
                {esquema.modulos.map((mod: ModuloSimple) => (
                  <IonItem key={mod.id}>
                    <IonLabel text-wrap>{mod.nombre}</IonLabel>
                    <IonRadio slot="end" value={mod.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === PAGINA_SUBMODULOS && (
            <IonList lines="full">
              <IonRadioGroup value={submodulo} onIonChange={e => {
                setSubmodulo(e.detail.value);
                if (autoAvanzar) { avanzar(); }
              }}
              >
                <IonListHeader lines="full" color='tertiary'>
                  <IonLabel>TOPOGRAFÍA</IonLabel>
                </IonListHeader>
                {esquema.submodulos.filter((x: Submodulo) => x.id_modulo === modulo).map((sub: Submodulo) => (
                  <IonItem key={sub.id}>
                    <IonLabel text-wrap>{sub.nombre}</IonLabel>
                    <IonRadio slot="end" value={sub.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === PAGINA_ETIOLOGIAS && (
            <IonList lines="full">
              <IonRadioGroup value={etiologia} onIonChange={e => {
                setEtiologia(e.detail.value);
                if (autoAvanzar) { avanzar(); }
              }}
              >
                <IonListHeader lines="full" color='tertiary'>
                  <IonLabel>ETIOLOGÍA</IonLabel>
                </IonListHeader>
                {esquema.etiologias.filter((x: Etiologia) => x.id_submodulo === submodulo).map((eti: Etiologia) => (
                  <IonItem key={eti.id}>
                    <IonLabel text-wrap>{eti.nombre}</IonLabel>
                    <IonRadio slot="end" value={eti.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === PAGINA_DIAGNOSTICOS && (
            <IonList lines="full">
              <IonRadioGroup value={diagnostico} onIonChange={e => {
                setDiagnostico(e.detail.value);
                if (autoAvanzar) { avanzar(); }
              }}
              >
                <IonListHeader lines="full" color='tertiary'>
                  <IonLabel>DIAGNÓSTICO</IonLabel>
                </IonListHeader>
                {esquema.diagnosticos.filter((x: Diagnostico) => x.id_etiologia === etiologia).map((dia: Diagnostico) => (
                  <IonItem key={dia.id}>
                    <IonLabel text-wrap>{dia.nombre}</IonLabel>
                    <IonRadio slot="end" value={dia.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === PAGINA_LOCALIZACIONES && (
            <IonList mode="md">
              <IonListHeader lines="full" color='tertiary'>
                <IonLabel>LOCALIZACIÓN</IonLabel>
              </IonListHeader>
              {
                localizaciones.filter((x: Item) => x.id_modulo === modulo).map(({ id, nombre, checked }) => (
                  <IonItem key={id}>
                    <IonLabel text-wrap>{nombre}</IonLabel>
                    <IonCheckbox
                      slot="end"
                      color="primary"
                      value={id}
                      checked={checked}
                      onIonChange={e => {
                        const item = localizaciones.find((x: Item) => x.id === id);
                        if (item) {
                          item.checked = (e.detail.checked);
                          setLocalizaciones([...localizaciones]);
                        }
                      }}
                    />
                  </IonItem>
                ))
              }
            </IonList>
          )
        }

        {
          pagina === PAGINA_VIAS && (
            <IonList lines="full">
              <IonRadioGroup value={via} onIonChange={e => {
                setVia(e.detail.value);
                if (autoAvanzar) { avanzar(); }
              }}
              >
                <IonListHeader lines="full" color='tertiary'>
                  <IonLabel>VÍA</IonLabel>
                </IonListHeader>
                {esquema.vias.filter((x: Via) => x.id_modulo === modulo).map((v: Via) => (
                  <IonItem key={v.id}>
                    <IonLabel text-wrap>{v.nombre}</IonLabel>
                    <IonRadio slot="end" value={v.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === PAGINA_TRATAMIENTOS && (
            <IonList lines="full">
              <IonListHeader lines="full" color='tertiary'>
                <IonLabel>TRATAMIENTO</IonLabel>
              </IonListHeader>
              <IonSearchbar
                mode="ios"
                value={tratamientoFiltro}
                placeholder="Filtrar Tratamientos..."
                onIonChange={e => setTratamientoFiltro(e.detail.value!)}
                showClearButton="always"
                debounce={100}
              >
              </IonSearchbar>
              <IonRadioGroup value={tratamiento} onIonChange={e => {
                setTratamiento(e.detail.value);
                if (autoAvanzar) { avanzar(); }
              }}
              >
                {
                  tratamientoNiveles.map((nivel: string) => armarNivelDeTratamiento(nivel))
                }
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === PAGINA_RESUMEN && (
            <IonList lines="full">
              <IonListHeader lines="full" color="tertiary">
                <IonLabel>RESUMEN</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel>
                  Módulo
                </IonLabel>
                {validarCampo('modulo')}
              </IonItem>
              {
                chequearSubmodulos(modulo, false) && (
                  <IonItem>
                    <IonLabel>
                      Topografía
                    </IonLabel>
                    {validarCampo('submodulo')}
                  </IonItem>
                )
              }
              <IonItem>
                <IonLabel>
                  Etiología
                </IonLabel>
                {validarCampo('etiologia')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Diagnóstico
                </IonLabel>
                {validarCampo('diagnostico')}
              </IonItem>
              {
                chequearLocalizaciones(modulo) && (
                  <IonItem>
                    <IonLabel>
                      Localización
                    </IonLabel>
                    {validarCampo('localizacion')}
                  </IonItem>
                )
              }
              {
                chequearVias(modulo) && (
                  <IonItem>
                    <IonLabel>
                      Vía
                    </IonLabel>
                    {validarCampo('via')}
                  </IonItem>
                )
              }
              <IonItem>
                <IonLabel>
                  Tratamiento
                </IonLabel>
                {validarCampo('tratamiento')}
              </IonItem>
            </IonList>
          )
        }

      </IonContent>
      <IonFooter>
        <IonToolbar color="tertiary">
          <IonButtons slot="end">
            {
              pagina === PAGINA_RESUMEN
                ? (
                  <IonButton color="success" mode="md" fill="solid" onClick={guardar} disabled={!validarAvance()}>
                    Guardar
                    <IonIcon slot="end" icon={checkmarkOutline} />
                  </IonButton>
                ) : (
                  <IonButton onClick={avanzar} disabled={!validarAvance()}>
                    Siguiente
                    <IonIcon slot="end" icon={chevronForwardOutline} />
                  </IonButton>
                )
            }
          </IonButtons>
          <IonButtons slot="start">
            {
              pagina > PAGINA_MODULOS && (
                <IonButton onClick={retroceder}>
                  Anterior
                  <IonIcon slot="start" icon={chevronBackOutline} />
                </IonButton>
              )
            }
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default NuevoModuloPage;
