import React from 'react';
import { IonThumbnail } from '@ionic/react';

const LogoAaot: React.FC = () => {
  return (
    <IonThumbnail slot="start" style={{ marginRight: -15, padding: '0 0 3px' }}>
        <img
        alt=""
        style={{ objectFit: "contain" }}
        src="/assets/img/aaot-transparente-apple.png"
        />
    </IonThumbnail> 
  );
};

export default LogoAaot;
