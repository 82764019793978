import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonText, IonMenuButton, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonToast, IonSpinner, IonButton } from '@ionic/react';
import './Novedades.scss';
import axios from 'axios';
import { AppContext } from '../AppContext';
// import { format } from 'date-fns'
// https://codesandbox.io/s/date-fns-example-pvkpn?file=/src/index.js
import { format, parse } from 'date-fns'
// import { es } from 'date-fns/locale'
import { Novedad } from '../models/Novedad';
import LogoAaot from '../components/LogoAaot';
import { pinOutline, searchOutline } from 'ionicons/icons';
import Log from '../components/Log';

/* https://www.positronx.io/ionic-card-component-example/ */

const Novedades: React.FC = () => {

  const [novedades, setNovedades] = useState<Array<Novedad>>([]);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [showCompleteToast, setShowCompleteToast] = useState(false);

  const {
    isLoading,
    usuario,
    setMostrarSponsor,
  } = useContext(AppContext);

  const doRefresh = (mensaje: boolean = true) => {
    setRefreshing(true);
    axios.get(`${process.env.REACT_APP_API_URL}/novedades?activas`)
      .then((res) => {
        const items: Array<Novedad> = res.data;
        // console.dir(items);
        setNovedades(items);
        if (mensaje) {
          setShowCompleteToast(true);
        }
        setRefreshing(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Novedades.tsx » doRefresh()`
        );
        setRefreshing(false);
      });
  };

  useEffect(() => {
    if (!refreshing && ionRefresherRef.current) {
      ionRefresherRef.current!.complete();
    }
  }, [refreshing]);

  useEffect(() => {
    doRefresh(false);
    // eslint-disable-next-line
  }, []);

  const abrirUrl = (url: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  interface SubtituloProps {
    fecha: string | undefined;
    fijado: boolean | undefined;
  }

  const Subtitulo: React.FC<SubtituloProps> = ({ fecha, fijado }) => {
    const _fecha = fecha || "";
    const _fijado: number = typeof fijado === "number" ? fijado : 0;
    return (
      <IonCardSubtitle>
        <IonItem lines="none">
          <IonLabel no-padding>
            <IonText color="medium">
              <small>
                {/* {formatDistanceStrict(new Date(_fecha), new Date(), { locale: es, unit: "day", addSuffix: true })} */}
                {_fecha && format(parse(_fecha.substring(0, 10), "yyyy-MM-dd", new Date()), "dd/MM/yyyy")}
              </small>
            </IonText>
          </IonLabel>
          {
            _fijado === 1 && <IonIcon slot="end" icon={pinOutline} />
          }
        </IonItem>
      </IonCardSubtitle>
    )
  };

  return (
    <IonPage id="novedades">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Novedades</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToolbar className="toolbar-sponsor ion-no-padding" style={{ cursor: "pointer" }} onClick={() => setMostrarSponsor(true)}>
        <img src={`${process.env.REACT_APP_API_FOTOS}/bannerBDolor_1200x200.png`} alt="" />
        <IonButtons slot="end" style={{  }}>
          <IonButton fill="solid" shape="round" size="small" color="primary" onClick={() => setMostrarSponsor(true)} style={{ textTransform: "none" }}>
            <IonIcon slot="icon-only" icon={searchOutline} size="small" />
            {/* <span className="ion-hide-sm-up">Más info</span> */}
            <span className="ion-hide-sm-down">VDM Línea dolor Bagó</span>
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent fullscreen={true}>
        <IonToast
          isOpen={showCompleteToast}
          message="Novedades actualizadas"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
        <div style={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          // border: "2px solid blue",
        }}>
          <IonGrid fixed style={{ paddingTop: '16px' }}>
            {
              isLoading() && (
                <IonSpinner name="crescent" />
              )
            }
            <IonRow>
              {
                novedades.map(novedad => {
                  return novedad.imagen
                    ? (
                      <IonCol key={novedad.id} size="12" size-md="6" size-xl="4">
                        <IonCard mode="ios" onClick={() => novedad?.link ? abrirUrl(novedad.link) : null}>
                          <img src={novedad.imagen} alt="" />
                          <IonCardHeader>
                            <IonCardTitle>{novedad.titulo}</IonCardTitle>
                            <Subtitulo fecha={novedad.fecha} fijado={novedad.fijado} />
                          </IonCardHeader>
                          <IonCardContent style={{ textAlign: "justify" }}>
                            {novedad.texto}
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    ) : (
                      <IonCol key={novedad.id} size="12" size-md="6" size-xl="4">
                        <IonCard mode="ios" style={{
                          color: novedad.color || 'black',
                          backgroundColor: novedad.fondo || 'white'
                        }}
                        >
                          <IonCardHeader>
                            <IonCardTitle style={{ color: novedad.color || 'black' }}>
                              {novedad.titulo}
                            </IonCardTitle>
                            <Subtitulo fecha={novedad.fecha} fijado={novedad.fijado} />
                          </IonCardHeader>
                          <IonCardContent>
                            {novedad.texto}
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    );
                })
              }
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Novedades;
