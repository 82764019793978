import React from 'react';
import {
  ColumnDirective, ColumnsDirective,
  GridComponent, Inject, Page, Sort,
  CommandColumn, Toolbar, ExcelExport
} from '@syncfusion/ej2-react-grids';
import './GrillaDeUsuarios.css';

const GrillaDeUsuarios = ({ modo, data, onEdit }) => {

  const comandos = [
    { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  ];

  // https://ej2.syncfusion.com/react/documentation/grid/excel-export/excel-exporting/
  // https://ej2.syncfusion.com/react/documentation/grid/tool-bar/tool-bar-items/

  let grid = null;

  const toolbarOptions = [
    { text: 'Exportar', tooltipText: 'Exportar a Excel', prefixIcon: 'e-excelexport', id: 'excel-export' }
  ];

  const toolbarClick = (args) => {
    if (grid && args.item.id === "excel-export") {
      const excelExportProperties = {
        fileName: modo === "administradores" ? "administradores.xlsx" : "usuarios.xlsx"
      };
      grid.excelExport(excelExportProperties);
    }
  };

  const commandClick = (args) => {
    const tipo = args.commandColumn.type;
    const item = args.rowData;
    if (tipo === "Edit") {
      onEdit(item);
    }
  };

  return modo === "administradores"
    ? (
      <GridComponent
        dataSource={data}
        locale={"es"}
        allowPaging
        allowSorting
        ref={g => grid = g}
        toolbar={toolbarOptions}
        allowExcelExport={true}
        toolbarClick={toolbarClick}
        commandClick={commandClick}
        pageSettings={{ pageSize: 10, pageCount: 3 }}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" headerText="Id" visible={true} isPrimaryKey width={100} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="nombre" headerText="Nombre" textAlign="Left" width={200} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="nombre_completo" headerText="Nombre completo" textAlign="Left" width={200} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="email" headerText="Email" textAlign="Left" width={200} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="activo" headerText="Activo" headerTextAlign="Center" textAlign="Center" width={90} displayAsCheckBox="true" type="boolean" hideAtMedia="(min-width:800px)" />
          <ColumnDirective headerText="" width="75" commands={comandos}></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Page, Sort, CommandColumn, Toolbar, ExcelExport]} />
      </GridComponent>
    ) : (
      <GridComponent
        dataSource={data}
        locale={"es"}
        allowPaging
        allowSorting
        ref={g => grid = g}
        toolbar={toolbarOptions}
        allowExcelExport={true}
        toolbarClick={toolbarClick}
        commandClick={commandClick}
        pageSettings={{ pageSize: 10, pageCount: 3 }}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" headerText="Id" visible={true} isPrimaryKey width={120} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="id_cliente" headerText="Cliente" headerTextAlign="Left" textAlign="Left" width={100} hideAtMedia="(min-width:1200px)" />
          <ColumnDirective field="nombre_completo" headerText="Nombre completo" textAlign="Left" width={200} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="email" headerText="Email" textAlign="Left" width={200} clipMode="EllipsisWithTooltip" hideAtMedia="(min-width:800px)" />
          <ColumnDirective field="id_clasificacion" headerText="Tipo" headerTextAlign="Left" textAlign="Left" width={90} hideAtMedia="(min-width:1200px)" />
          <ColumnDirective field="residencia" headerText="Residencia" textAlign="Left" width={400} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="provincia" headerText="Provincia" textAlign="Left" width={200} clipMode="EllipsisWithTooltip" />          
          <ColumnDirective field="activo" headerText="Activo" headerTextAlign="Center" textAlign="Center" width={90} displayAsCheckBox="true" type="boolean" hideAtMedia="(min-width:800px)" />
          <ColumnDirective headerText="" width="75" commands={comandos}></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Page, Sort, CommandColumn, Toolbar, ExcelExport]} />
      </GridComponent>
    );
};

export default GrillaDeUsuarios;
