import React, { useState, useEffect, useContext } from 'react';
import { IonToolbar, IonContent, IonPage, IonButtons, IonFab, IonFabButton, IonTitle, IonMenuButton, IonIcon, IonToast, IonModal, IonHeader, IonSpinner, IonLoading } from '@ionic/react';
import { add } from 'ionicons/icons';
import './Mensajes.scss'
import AbmNovedad from './AbmNovedad';
import axios from 'axios';
import { Novedad } from '../models/Novedad';
import GrillaDeNovedades from '../components/GrillaDeNovedades';
import LogoAaot from '../components/LogoAaot';
import MensajeError from '../components/MensajeError';
import Log from '../components/Log';
import { AppContext } from '../AppContext';

const MensajesPage: React.FC = () => {

  const [item, setItem] = useState<Novedad | undefined>();
  const [showAbm, setShowAbm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [novedades, setNovedades] = useState<Novedad[]>([]);
  const [showMsg, setShowMsg] = useState("");

  const {
    usuario
  } = useContext(AppContext);
  
  const cargarNovedades = () => {
    setError("");
    setLoader(true);
    const url = `${process.env.REACT_APP_API_URL}/novedades`;
    axios.get(url)
      .then((res) => {
        // console.dir(res.data);
        setNovedades(res.data);
        setLoader(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Mensajes.tsx » cargarNovedades()`
        );
        setLoader(false);
      });
  }

  useEffect(() => {
    cargarNovedades();
  // eslint-disable-next-line
  }, []);

  const onCreate = () => {
    setItem(undefined);
    setShowAbm(true);
  };

  const onEdit = (novedad: Novedad) => {
    setItem(novedad);
    setShowAbm(true);
  };

  const onCloseAbm = (novedad: Novedad | undefined) => {
    setError("");
    setShowAbm(false);
    if (novedad) {
      setLoading("Guardando novedad...");
      if (novedad.id) {
        axios.put(
          `${process.env.REACT_APP_API_URL}/novedades/${novedad.id}`, novedad)
          .then(async () => {
            setLoading("");
            cargarNovedades();
            setShowMsg("Novedad editada");
          }).catch((err) => {
            Log.error(
              err,
              usuario,
              `Mensajes.tsx » onCloseAbm(): Error al intentar editar la Novedad: ${JSON.stringify(novedad)}`
            );
            setError(`Error al intentar editar la novedad`);
            setLoading("");
          });
      } else {
        axios.post(
          `${process.env.REACT_APP_API_URL}/novedades`, novedad)
          .then(async () => {
            setError("");
            setLoading("");
            cargarNovedades();
            setShowMsg("Novedad creada");
          })
          .catch((err) => {
            Log.error(
              err,
              usuario,
              `Mensajes.tsx » onCloseAbm(): Error al intentar crear la Novedad: ${JSON.stringify(novedad)}`
            );
            setError(`Error al intentar crear la novedad`);
            setLoading("");
          });
      }
    }
  };

  const onRequestDelete = (novedad: Novedad) => {
    setShowAbm(false);
    if (novedad) {
      setLoading("Borrando novedad...");
      axios.delete(
        `${process.env.REACT_APP_API_URL}/novedades/${novedad.id}`)
        .then(async () => {
          setError("");
          setLoading("");
          cargarNovedades();
          setShowMsg("Novedad borrada");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Mensajes.tsx » onRequestDelete(): Error al intentar borrar la Novedad: ${JSON.stringify(novedad)}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  return (
    <IonPage id="mensajes-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Novedades</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        {
          error && <MensajeError mensaje={error} onClose={() => setError("")} />
        }
        {
          loader
            ? (
              <IonSpinner name="crescent" color="secondary" />
            ) : (
              <GrillaDeNovedades
                data={novedades}
                onEdit={onEdit}
              />
            )
        }
        <IonToast
          isOpen={showMsg !== ""}
          message={showMsg}
          duration={2000}
          onDidDismiss={() => setShowMsg("")}
        />
        <IonLoading
          isOpen={loading !== ""}
          onDidDismiss={() => setLoading("")}
          message={loading}
          duration={60000}
        />
      </IonContent>
      <IonModal
        isOpen={showAbm}
        onDidDismiss={() => setShowAbm(false)}
        swipeToClose={true}
      >
        <AbmNovedad
          item={item}
          onDismissModal={onCloseAbm}
          onRequestDelete={onRequestDelete}
        />
      </IonModal>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="primary" onClick={onCreate}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default MensajesPage;
