import React, { useContext, useState } from 'react';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonInput, IonIcon, IonNote } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { Banner } from '../models/Banner';
import { AppContext } from '../AppContext';
import './AbmBanner.scss'

interface AbmBannerProps {
  item: Banner | undefined,
  onDismissModal: (banner: Banner | undefined) => void;
  uploadFoto: (ubicacion: string, archivo: string) => void;
}

const AbmBannerPage: React.FC<AbmBannerProps> = ({ item, onDismissModal, uploadFoto }) => {

  const {
    usuario
  } = useContext(AppContext);

  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      id: item?.id || "",
      ubicacion: item?.ubicacion || "",
      link: item?.link || "",
      video: item?.video || "",
      imagen1: item?.imagen1 || "",
      // TODO más adelante?
      // imagen2: item?.imagen2 || "",
      // imagen3: item?.imagen3 || "",
      // imagen4: item?.imagen4 || "",
      // imagen5: item?.imagen5 || "",
      // texto: item?.texto || "",
      // boton: item?.boton || "",      
      // color: item?.color || "#000000",
      // fondo: item?.fondo || "#FFFFFF",
    }
  });

  const onSubmit = (data: any) => {
    if (!item) {
      return;
    }
    // console.dir(data);
    if (img1 && item.imagen1 !== img1) {
      uploadFoto(item.ubicacion, img1);
    }
    const banner = {
      id: data.id || null,
      ubicacion: data.ubicacion || null,
      link: data.link || null,
      video: data.video || null,
      // No se modifica, siempre es la misma
      // imagen1: data.imagen1 || null,
      // TODO más adelante?
      // imagen2: data.imagen2 || null,
      // imagen3: data.imagen3 || null,
      // imagen4: data.imagen4 || null,
      // imagen5: data.imagen5 || null,
      // color: data.color || "#000000",
      // fondo: data.fondo || "#FFFFFF",
      // texto: data.texto || null,
      // boton: data.boton || null,
      id_usuario: usuario?.id
    };
    onDismissModal(banner);
  };

  const openFileDialog = () => {
    (document as any).getElementById("file-upload").click();
  };
 
  const setImage = (_event: any) => {
    let f = _event.target.files![0];
    setImg1(URL.createObjectURL(f));
  }

  const [img1, setImg1] = useState(item?.imagen1);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage id="abm-banner">
        <IonHeader translucent={false}>
          <IonToolbar color="primary">
            <IonButtons slot="start">
            </IonButtons>
            <IonTitle>
              {item ? "Editar" : "Nueva"}&nbsp;Banner
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismissModal(undefined)}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList lines="full">
            <IonItem>
              <IonLabel position="stacked">Id</IonLabel>
              <IonInput
                value={item?.id}
                disabled
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Ubicación</IonLabel>
              <IonInput
                value={item?.ubicacion}
                disabled
              ></IonInput>
            </IonItem>            
            <IonItem lines="none">
              <IonLabel>
                Imagen principal
              </IonLabel>                     
              {/* onClick={() => setEditarFoto(true)} */}
              <IonButton fill="outline" slot="end" onClick={openFileDialog}>
                Editar
              </IonButton>
              <input
                type="file"
                id="file-upload"
                onChange={setImage}
                style={{ display: "none" }}                
              />
            </IonItem>
            <IonItem>
              <img
                style={{ marginBottom: 15 }}
                src={img1}
                alt=""
              />
            </IonItem>
            <IonItem className={errors.link ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Enlace</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese la URL del Enlace"
                  />
                )}
                control={control}
                name="link"
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Campo requerido"
              //   }
              // }}
              />
              <IonNote slot="error">{errors?.link?.message}</IonNote>
            </IonItem>
            <IonItem className={errors.video ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Video</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese la URL del Video"
                  />
                )}
                control={control}
                name="video"
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Campo requerido"
              //   }
              // }}
              />
              <IonNote slot="error">{errors?.video?.message}</IonNote>
            </IonItem>
            {/* <IonItem className={errors.texto ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Texto</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el texto"
                  />
                )}
                control={control}
                name="texto"
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Campo requerido"
              //   }
              // }}
              />
              <IonNote slot="error">{errors?.texto?.message}</IonNote>
            </IonItem> */}
            {/* <IonItem className={errors.boton ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Botón</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el texto del Botón"
                  />
                )}
                control={control}
                name="boton"
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Campo requerido"
              //   }
              // }}
              />
              <IonNote slot="error">{errors?.boton?.message}</IonNote>
            </IonItem> */}
            {/* http://casesandberg.github.io/react-color/ */}
            {/* <IonItem style={{ paddingBottom: 10 }} lines="none">
              <IonLabel position="floating">
                Colores
              </IonLabel>
              <IonGrid no-padding>
                <IonRow no-padding >
                  <IonCol>
                    <div style={{
                      color: watch("color"),
                      backgroundColor: watch("fondo"),
                      textAlign: "center",
                      padding: "5px 0px !important",
                      margin: "0px 10px !important",
                      boxShadow: "rgb(0 0 0 / 12%) 0px 2px 10px, rgb(0 0 0 / 16%) 0px 2px 5px",
                      borderRadius: "2px"
                    }}>
                      EJEMPLO
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow no-padding>
                  <IonCol no-padding>
                    <Controller
                      render={({ field }) => (
                        <CompactPicker
                          color={field.value}
                          onChangeComplete={(e) => field.onChange(e.hex)}
                        />
                      )}
                      control={control}
                      name="color"
                    />
                  </IonCol>
                </IonRow>
                <IonRow no-padding >
                  <IonCol no-padding>
                    <Controller
                      render={({ field }) => (
                        <CompactPicker
                          color={field.value}
                          onChangeComplete={(e) => field.onChange(e.hex)}
                        />
                      )}
                      control={control}
                      name="fondo"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem> */}
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar color="tertiary">
            <IonButtons slot="end">
              <IonButton color="success" mode="md" fill="solid" type="submit" disabled={!isValid}>
                Guardar
                <IonIcon slot="end" icon={checkmarkOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default AbmBannerPage;
