import React, { useState } from 'react';
import { IonIcon, IonList, IonItem, IonLabel, IonToast } from '@ionic/react';
import { callOutline, locationOutline, mailOutline, logoWhatsapp, logoInstagram, logoFacebook, logoTwitter, logoYoutube, logoLinkedin, globeOutline, informationCircle } from 'ionicons/icons';
import { AAOT_FACEBOOK, AAOT_INSTAGRAM, AAOT_LINKEDIN, AAOT_MAIL, AAOT_MAPA, AAOT_TEL, AAOT_TWITTER, AAOT_WEB, AAOT_WHATSAPP, AAOT_YOUTUBE } from '../constantes';
import './Contacto.scss';

const abrirWeb = () => {
  window.open(AAOT_WEB, '_blank');
};

const abrirMapa = () => {
  window.open(AAOT_MAPA, '_blank');
};

const abrirWhatsApp = () => {
  window.open(AAOT_WHATSAPP, '_blank');
};

const abrirInstagram = () => {
  window.open(AAOT_INSTAGRAM, '_blank');
};

const abrirFacebook = () => {
  window.open(AAOT_FACEBOOK, '_blank');
};

const abrirTwitter = () => {
  window.open(AAOT_TWITTER, '_blank');
};
const abrirLinkedin = () => {
  window.open(AAOT_LINKEDIN, '_blank');
};

const abrirYoutube = () => {
  window.open(AAOT_YOUTUBE, '_blank');
};

const Contacto: React.FC = () => {

  const [showMsg, setShowMsg] = useState("");
  
  const abrirMail = async () => {
    // No anduvo mailto..
    // const mail = document.createElement("a");
    // mail.href = `mailto:${AAOT_MAIL}?subject=Consulta desde App de Residentes`;
    // mail.click();
    // Copio al Clibboard
    // https://usehooks-ts.com/react-hook/use-copy-to-clipboard
    if (!navigator?.clipboard) {
      setShowMsg("Portapapeles no soportado");
      return;
    }
    try {
      await navigator.clipboard.writeText(AAOT_MAIL);
      setShowMsg("Email copiado al Portapapeles");
    } catch (error) {
      console.warn('Copy failed', error);
      setShowMsg("No pudo copiarse el Email al Portapapeles");
    }
  };

  return (
    <>
      <div className="contacto-logo">
        <img src="assets/img/aaot-institucional.png" alt="" />
      </div>
      <div className="contacto-info">
        <p className="ion-padding-start ion-padding-end" style={{ textAlign: 'justify' }}>
          Nuestra misión es contribuir al progreso de la Medicina en el campo de la Ortopedia y Traumatología, realizar y fomentar en el país el estudio del aparato locomotor en sus aspectos biólogicos, experimentales, anátomo patológicos, clínicos, quirúrgicos y sociales.
        </p>
        <h3 className="ion-padding-top ion-padding-start">Contáctanos en</h3>
        <IonList lines="none">
          <IonItem button onClick={() => abrirWeb()} detail={false}>
            <IonIcon slot="start" icon={globeOutline} />
            <IonLabel text-wrap>
              {AAOT_WEB}
            </IonLabel>
          </IonItem>          
          <IonItem button onClick={() => abrirMapa()} detail={false}>
            <IonIcon slot="start" icon={locationOutline} />
            <IonLabel text-wrap>
              Vicente López 1878, CABA
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => abrirMail()} detail={false}>
            <IonIcon slot="start" icon={mailOutline} />
            <IonLabel text-wrap>
              {AAOT_MAIL}
            </IonLabel>
          </IonItem> 
          <IonItem button href={AAOT_TEL} detail={false}>
            <IonIcon slot="start" icon={callOutline} />
            <IonLabel text-wrap>
            +54 11 4801 2320
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => abrirWhatsApp()} detail={false}>
            <IonIcon slot="start" icon={logoWhatsapp} />
            <IonLabel text-wrap>
              WhatsApp 5491134252320
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => abrirInstagram()} detail={false}>
            <IonIcon slot="start" icon={logoInstagram} />
            <IonLabel text-wrap>
              @aaotorg
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => abrirFacebook()} detail={false}>
            <IonIcon slot="start" icon={logoFacebook} />
            <IonLabel text-wrap>
              @AAOTorg
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => abrirTwitter()} detail={false}>
            <IonIcon slot="start" icon={logoTwitter} />
            <IonLabel text-wrap>
              @AAOTorg
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => abrirLinkedin()} detail={false} lines="none">
            <IonIcon slot="start" icon={logoLinkedin} />
            <IonLabel text-wrap>
              {AAOT_LINKEDIN}
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => abrirYoutube()} detail={false}>
            <IonIcon slot="start" icon={logoYoutube} />
            <IonLabel text-wrap>
              Youtube
            </IonLabel>
          </IonItem>            
        </IonList>
      </div>
      <IonToast
        isOpen={showMsg !== ""}
        message={showMsg}
        duration={2000}
        icon={informationCircle}
        onDidDismiss={() => setShowMsg("")}
      />      
    </>
  );
};

export default React.memo(Contacto);
