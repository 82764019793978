import React from 'react';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { alertCircleOutline, closeOutline } from 'ionicons/icons';

interface MensajeErrorProps {
  mensaje: string;
  onClose: () => void;
}

const MensajeError: React.FC<MensajeErrorProps> = ({ mensaje, onClose}) => {
  return (
    <IonChip color="danger" onClick={() => onClose()} className="mensaje mensaje--error">
      <IonIcon icon={alertCircleOutline} color="primary" />
      <IonLabel>{mensaje}</IonLabel>
      <IonIcon icon={closeOutline} />
    </IonChip>
  );
};

export default MensajeError;
