import React, { useState, useEffect, useContext } from 'react';
import { IonToolbar, IonContent, IonPage, IonButtons, IonTitle, IonMenuButton, IonIcon, IonToast, IonModal, IonHeader, IonChip, IonLabel, IonSpinner, IonLoading } from '@ionic/react';
import { closeOutline, alertCircleOutline } from 'ionicons/icons';
import AbmUsuario from './AbmUsuario';
import axios from 'axios';
import { Usuario } from '../models/Usuario';
import GrillaDeUsuarios from '../components/GrillaDeUsuarios';
import LogoAaot from '../components/LogoAaot';
import './Usuarios.scss'
import Log from '../components/Log';
import { AppContext } from '../AppContext';

const UsuariosPage: React.FC = () => {

  const [item, setItem] = useState<Usuario | undefined>();
  const [showAbm, setShowAbm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState("");  
  const [error, setError] = useState("");
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [showMsg, setShowMsg] = useState("");

  const {
    usuario
  } = useContext(AppContext);
  
  const cargarUsuarios = () => {
    setError("");
    setLoader(true);
    const url = `${process.env.REACT_APP_API_URL}/usuarios`;
    axios.get(url)
    .then((res) => {
      // console.dir(res.data.data);
      setUsuarios(res.data.data);
      setLoader(false);
    }).catch((err) => {
      Log.error(
        err,
        usuario,
        `Usuarios.tsx » cargarUsuarios()`
      );
      setLoader(false);
      setError(error);
    });
  }

  useEffect(() => {
    cargarUsuarios();
    // eslint-disable-next-line
  }, []);

  const onEdit = (usuario: Usuario) => {
    setItem(usuario);
    setShowAbm(true);
  };

  const onRequestActivo = (usuario: Usuario, activo: boolean) => {
    // console.log("onRequestActivo", usuario, activo);
    setError("");
    setShowAbm(false);
    setLoading("Actualizando usuario...");
    axios.patch(
      `${process.env.REACT_APP_API_URL}/usuarios/${usuario.id}`, { "activo": activo })
      .then(async () => {
        setLoading("");
        cargarUsuarios();
        setShowMsg("Usuario actualizado");
      })
      .catch((err) => {
        Log.error(
          err,
          usuario,
          `Usuarios.tsx » onRequestActivo(): Error al intentar editar como ${activo ? 'ACTIVO' : 'INACTIVO'} al Usuario: ${JSON.stringify(usuario)}`
        );
        const msg = err && err.response && err.response.data
          ? err.response.data.message
          : 'Error al intentar esta operación';
        setError(msg);
        setLoading("");
      });
  };

  return (
    <IonPage id="usuarios-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Usuarios</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        {
          error && (
            <IonChip color="danger" onClick={() => setError("")}>
              <IonIcon icon={alertCircleOutline} color="primary" />
              <IonLabel>{error}</IonLabel>
              <IonIcon icon={closeOutline} />
            </IonChip>
          )
        }
        {
          loader
          ? (
            <IonSpinner name="crescent" color="secondary" />
          ) : (
            <GrillaDeUsuarios
              modo="usuarios"
              data={usuarios}
              onEdit={onEdit}
            />
          )
        }
        <IonToast
          isOpen={showMsg !== ""}
          message={showMsg}
          duration={2000}
          onDidDismiss={() => setShowMsg("")}
        />
        <IonLoading
          isOpen={loading !== ""}
          onDidDismiss={() => setLoading("")}
          message={loading}
          duration={60000}
        />
      </IonContent>
      <IonModal
        isOpen={showAbm}
        onDidDismiss={() => setShowAbm(false)}
        swipeToClose={true}
      >
        <AbmUsuario
          item={item}
          onDismissModal={() => setShowAbm(false)}
          onRequestActivo={onRequestActivo}
        />
      </IonModal>
    </IonPage>
  );
};

export default UsuariosPage;
