import React from 'react';
import {
  ColumnDirective, ColumnsDirective,
  GridComponent, Inject, Page, Sort,
  Toolbar, DetailRow, ExcelExport
} from '@syncfusion/ej2-react-grids';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import './GrillaDePracticas.scss';
import { downloadOutline } from 'ionicons/icons';
import { IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';

// https://ej2.syncfusion.com/react/documentation/grid/excel-export/excel-exporting/
// https://ej2.syncfusion.com/react/documentation/grid/tool-bar/tool-bar-items/


// Grid Hierarchy
// https://ej2.syncfusion.com/react/demos/#/material/grid/hierarchy
// https://ej2.syncfusion.com/react/documentation/api/grid/#querystring
// https://ej2.syncfusion.com/react/demos/#/material/grid/master-details-export
// https://ej2.syncfusion.com/react/documentation/grid/hierarchy-grid/#expandall-by-external-button

const GrillaDePracticas = ({ data }) => {

  let grid = null;

  const exportar = (tipo) => {
    if (grid) {
      const excelExportProperties = {
        fileName: "practicas.xlsx",
        hierarchyExportMode: tipo
      };
      grid.showSpinner();
      grid.excelExport(excelExportProperties);
    }
  };

  const excelExportComplete = () => {
    if (grid) {
      grid.hideSpinner();
    }
  };

  const dataDetalle = new DataManager({
    adaptor: new WebApiAdaptor(),
    // https://ej2.syncfusion.com/react/documentation/grid/data-binding/#sending-additional-parameters-to-server
    url: `${process.env.REACT_APP_API_URL}/practicas/modulos`,
  });

  const gridModulos = {
    dataSource: dataDetalle,
    allowPaging: false,
    allowSorting: false,
    locale: "es",
    columns: [
      { field: 'modulo', headerText: 'Módulo' },
      { field: 'submodulo', headerText: 'Topografía' },
      { field: 'etiologia', headerText: 'Etiología' },
      { field: 'diagnostico', headerText: 'Diagnóstico' },
      { field: 'tratamiento', headerText: 'Tratamiento' },
      { field: 'via', headerText: 'Vía' },
      { field: 'localizaciones', headerText: 'Localizaciones' }
    ],
    load() {
      const str = 'id';
      (this).parentDetails.parentKeyFieldValue =
        (this).parentDetails.parentRowData[str];
    },
    queryString: 'id_practica'
  };

  return (
    <div style={{
      width: "100%",
      height: "100%",
      maxHeight: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
      // border: "2px solid blue",
    }}>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton size="small" onClick={() => exportar("None")}>
            <IonIcon slot="start" icon={downloadOutline} />
            <span className="ion-hide-sm-down">Exportar</span>&nbsp;Prácticas
          </IonButton>
          <IonButton size="small" onClick={() => exportar("All")}>
            <IonIcon slot="start" icon={downloadOutline} />
            <span className="ion-hide-sm-down">Exportar</span>&nbsp;Todo
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <div style={{
        width: "100%",
        height: "calc(100% - 56px)",
        maxHeight: "calc(100% - 56px)",
        overflowX: "hidden",
        overflowY: "hidden",
        // border: "2px solid green",
      }}>
        <GridComponent
          id="grilla-de-practicas"
          dataSource={data}
          locale={"es"}
          allowPaging={false}
          allowSorting={false}
          ref={g => grid = g}
          allowExcelExport={true}
          enableStickyHeader
          selectionSettings={{ persistSelection: false, type: "Single" }}
          childGrid={gridModulos}
          enableAdaptiveUI={false}
          excelExportComplete={excelExportComplete}
          width="100%"
          height="100%"
          maxHeight="100%"
          overflowY="scroll"
          overflowX="scroll"
        >
          {/* hideAtMedia="(min-width:800px)" */}
          <ColumnsDirective>
            <ColumnDirective field="id" headerText="Id" visible={true} isPrimaryKey width={80} headerTextAlign="Right" textAlign="Right" />
            <ColumnDirective field="fecha" headerText="Fecha" headerTextAlign="Left" textAlign="Left" width={120} />
            <ColumnDirective field="provincia" headerText="Provincia" textAlign="Left" width={120} />
            <ColumnDirective field="institucion" headerText="Institución" textAlign="Left" width={250} />
            <ColumnDirective field="residente" headerText="Residente" textAlign="Left" width={200} />
            <ColumnDirective field="email" headerText="Email" textAlign="Left" width={200} />
            <ColumnDirective field="hc" headerText="HC" headerTextAlign="Left" textAlign="Left" width={100} />
            <ColumnDirective field="sexo" headerText="Sexo" headerTextAlign="Left" textAlign="Left" width={100} />
            <ColumnDirective field="edad" headerText="Edad" headerTextAlign="Right" textAlign="Right" width={100} />
            <ColumnDirective field="modulos" headerText="Módulos" headerTextAlign="Center" textAlign="Right" width={100} />
            <ColumnDirective field="rol" headerText="Rol" headerTextAlign="Left" textAlign="Left" width={120} />
            <ColumnDirective field="urgencia" headerText="Urgencia" headerTextAlign="Left" textAlign="Left" width={120} />
            <ColumnDirective field="notas" headerText="Notas" headerTextAlign="Left" textAlign="Left" width={250} />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Toolbar, DetailRow, ExcelExport]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default GrillaDePracticas;
