import { Institucion } from "./Institucion";
import { UsuarioSimple } from "./UsuarioSimple";
import { startOfYear, format } from 'date-fns';
import { Usuario } from "./Usuario";

export interface Filtros {
    desde: Date | undefined;
    hasta: Date | undefined;
    provincia: number;
    institucion: Institucion | null;
    sexo: string;
    edades: number[];
    modulo: string;
    urgencia: string;
    rolQuirofano: number;
    residente: UsuarioSimple | null;
    hc: string;
};

export const filtrosDefault: Filtros = {
    desde: undefined,
    hasta: undefined,
    provincia: 0,
    institucion: null,
    sexo: "",
    edades: [0, 120],
    modulo: "",
    urgencia: "",
    rolQuirofano: 0,
    residente: null,
    hc: ""
};

export const defaultFiltros = (usuario: Usuario) => {
    if (usuario.administrador) {
        const ahora = new Date();
        // Último mes (30-31 días hacia atrás)
        // return {...filtrosDefault, desde: addMonths(ahora, -1), hasta: ahora};
        // Año actual
        return {...filtrosDefault, desde: startOfYear(ahora), hasta: ahora};
    }
    const residente: UsuarioSimple = {
        id: usuario?.id || 0,
        nombre_completo: usuario?.nombre_completo || "",
        email: usuario?.email || ""
    };
    return {...filtrosDefault, residente: residente};
};

export const filtrar = (filtros: Filtros) : string => {
    const items: string[] = [];
    if (filtros.desde) {
        items.push(`desde=${format(filtros.desde, 'yyyy-MM-dd')}`);
    }
    if (filtros.hasta) {
        items.push(`hasta=${format(filtros.hasta, 'yyyy-MM-dd')}`);
    }
    if (filtros.provincia) {
        items.push(`provincia=${filtros.provincia}`);
    }
    if (filtros.institucion) {
        items.push(`institucion=${filtros.institucion.id}`);
    }
    if (filtros.sexo) {
        items.push(`sexo=${filtros.sexo}`);
    }
    if (filtros.edades && (filtros.edades[0] > 0 || filtros.edades[1] < 120)) {
        items.push(`edades=${filtros.edades[0]}-${filtros.edades[1]}`);
    }
    if (filtros.modulo) {
        items.push(`modulo=${filtros.modulo}`);
    }
    if (filtros.urgencia) {
        items.push(`urgencia=${filtros.urgencia}`);
    }
    if (filtros.rolQuirofano) {
        items.push(`quirofano=${filtros.rolQuirofano}`);
    }
    if (filtros.residente) {
        items.push(`residente=${filtros.residente.id}`);
    }
    if (filtros.hc) {
        items.push(`hc=${filtros.hc}`);
    }
    return items.join("&");
};