import { Usuario } from '../models/Usuario';
import * as Sentry from "@sentry/browser";

class Log {

  static error(err: any, usuario: Usuario | undefined, msg: string) {
    Sentry.captureException(err, (scope) => {
      scope.clear();
      scope.setUser({
        id: (usuario?.id || "").toString(),
        email: usuario?.email || ""
      });
      scope.addBreadcrumb({
          type: "error",
          category: "error",
          level: Sentry.Severity.Error,
          message: msg
      });
      return scope;
    });
  }

}

export default Log;
