import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { IonDatetime, IonRadioGroup, IonRadio, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonListHeader, IonItem, IonLabel, IonFooter, IonInput, IonModal, IonProgressBar, IonIcon, IonSearchbar, IonSegment, IonSegmentButton, IonFab, IonFabButton, IonTextarea, IonText, IonItemDivider, useIonViewDidEnter, useIonAlert } from '@ionic/react';
import { add, checkmarkOutline, chevronBackOutline, chevronForwardOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons';
import { format, parse } from 'date-fns';
import { AppContext } from '../AppContext';
import NuevoModulo from './NuevoModulo';
import { Modulo } from '../models/Modulo';
import { Institucion } from '../models/Institucion';
import { Practica } from '../models/Practica';
import { OTRA_INSTITUCION } from '../constantes';
import './NuevaPractica.scss'

interface NuevaPracticaProps {
  practicaActual: Practica | undefined;
  onDismissModal: (nuevaPractica: any) => void;
}

const NuevaPracticaPage: React.FC<NuevaPracticaProps> = ({ practicaActual, onDismissModal }) => {

  const {
    esquema,
    usuario,
    autoAvanzar
  } = useContext(AppContext);
  const [showCargaModulo, setShowCargaModulo] = useState(false);
  const [pagina, setPagina] = useState(1);

  const [hc, setHc] = useState<string>(practicaActual?.hc || "");
  const [sexo, setSexo] = useState<string>(practicaActual?.sexo || "");
  const [edad, setEdad] = useState<number | null>(practicaActual?.edad || null);  
  const [institucion, setInstitucion] = useState<number | undefined>(practicaActual?.id_institucion || usuario?.id_institucion);
  const [institucionOtra, setInstitucionOtra] = useState(practicaActual?.lugar || undefined);
  const [fecha, setFecha] = useState(practicaActual?.fecha || format(new Date(), "yyyy-MM-dd"));
  const [modulos, setModulos] = useState<Modulo[]>(practicaActual?.modulos || []);
  const [urgencia, setUrgencia] = useState<string | null>(practicaActual?.id_urgencia || "P");
  const [rol, setRol] = useState<number | null>(practicaActual?.id_rol_quirofano || 1);
  const [notas, setNotas] = useState(practicaActual?.notas || "");

  const [filtroInstitucion, setFiltroInstitucion] = useState("");
  const [modoInstitucion, setModoInstitucion] = useState<"origen" | "todas" | "otra">(
    practicaActual?.id_institucion
    ? (
      practicaActual?.id_institucion === usuario?.id_institucion
      ? "origen"
      : "todas"
    )
    : (
      usuario?.id_institucion
        ? "origen"
        : "todas"
    )
  );
  const [institucionesLista, setInstitucionesLista] = useState<Institucion[]>([]);
  const [moduloActual, setModuloActual] = useState<Modulo>();

  useEffect(() => {
    setInstitucionesLista([...esquema.instituciones]);
  }, [esquema]);

  useEffect(() => {
    setInstitucionesLista([...esquema.instituciones]);
  }, [esquema]);

  useEffect(() => {
    if (esquema && esquema.instituciones) {
      if (filtroInstitucion) {
        let regexp = new RegExp(filtroInstitucion, "i");
        setInstitucionesLista([...esquema.instituciones.filter((i: Institucion) => i.nombre.match(regexp))]);
      } else {
        setInstitucionesLista([...esquema.instituciones]);
      }
    }
  }, [esquema, filtroInstitucion]);

  const armarProvincia = (provincia: any) => {
    const instituciones = institucionesLista.filter((i: Institucion) => i.id_provincia === provincia.id);
    if (!instituciones.length) {
      return null;
    }
    return (
      <Fragment key={provincia.id}>
        <IonItemDivider color="medium" sticky>
          {provincia.nombre.toUpperCase()}
        </IonItemDivider>
        {
          instituciones.length
            ? (
              instituciones.map((ins: Institucion) => (
                <IonItem key={ins.id}>
                  <IonLabel text-wrap>{ins.nombre}</IonLabel>
                  <IonRadio slot="end" value={ins.id} />
                </IonItem>
              )
              )) : (
              <IonItem color="light">
                <IonLabel className="gris">(Sin Instituciones)</IonLabel>
              </IonItem>
            )
        }
      </Fragment>
    );
  }

  const PAGINA_HC = 1;
  const PAGINA_SEXO = 2;
  const PAGINA_EDAD = 3;
  const PAGINA_INSTITUCION = 4;
  const PAGINA_FECHA = 5;
  const PAGINA_MODULOS = 6;
  const PAGINA_URGENCIA = 7;
  const PAGINA_ROL = 8;
  const PAGINA_NOTAS = 9;
  const PAGINA_RESUMEN = 10;

  const validarAvance = () => {
    if (pagina === PAGINA_HC) {
      return hc !== "";
    } else if (pagina === PAGINA_SEXO) {
      return sexo !== "";
    } else if (pagina === PAGINA_EDAD) {
      return edad !== null;
    } else if (pagina === PAGINA_INSTITUCION) {
      return institucion !== undefined;
    } else if (pagina === PAGINA_FECHA) {
      return true;
    } else if (pagina === PAGINA_MODULOS) {
      return modulos.length > 0;
    } else if (pagina === PAGINA_URGENCIA) {
      return urgencia !== null;
    } else if (pagina === PAGINA_ROL) {
      return rol !== null;
    } else if (pagina === PAGINA_NOTAS) {
      return true;
    }
    return true;
  };

  const validarAvanceConValor = (valor: any) => {
    if (pagina === PAGINA_HC) {
      return hc !== "";
    } else if (pagina === PAGINA_SEXO) {
      return valor !== "";
    } else if (pagina === PAGINA_EDAD) {
      return edad !== null;
    } else if (pagina === PAGINA_INSTITUCION) {
      return valor !== undefined;
    } else if (pagina === PAGINA_FECHA) {
      return true;
    } else if (pagina === PAGINA_MODULOS) {
      return modulos.length > 0;
    } else if (pagina === PAGINA_URGENCIA) {
      return valor !== null;
    } else if (pagina === PAGINA_ROL) {
      return valor !== null;
    } else if (pagina === PAGINA_NOTAS) {
      return true;
    }
    return true;
  };

  const avanzar = () => {
    let nuevaPagina: number = pagina + 1;
    if (nuevaPagina > PAGINA_RESUMEN) {
      nuevaPagina = PAGINA_RESUMEN;
    }
    setPagina(nuevaPagina);
  };

  const retroceder = () => {
    let nuevaPagina: number = pagina - 1;
    if (nuevaPagina < 1) {
      nuevaPagina = 1;
    }
    setPagina(nuevaPagina);
  };

  const guardar = () => {
    const nuevaPractica: Practica = {
      id_usuario: usuario?.id || 0,
      id: practicaActual?.id || undefined,
      hc: hc,
      sexo: sexo,
      edad: edad || undefined,
      id_institucion: institucion,
      institucion: esquema.instituciones.find((x: any) => x.id === institucion).nombre,
      lugar: institucionOtra,
      fecha: fecha,
      modulos: modulos,
      urgencia: urgencia || undefined,
      id_rol_quirofano: rol || undefined,
      notas: notas
    };
    onDismissModal(nuevaPractica);
  }

  // https://forum.ionicframework.com/t/set-focus-to-ioninput/203097/4
  const inputRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    if (inputRef && inputRef.current) {
      setTimeout(() => inputRef.current.setFocus(), 500);
    }
  });

  const validarCampo = (id: string) => {
    switch (id) {
      case "hc":
        return hc
          ? <IonLabel className="ion-text-end"><IonText color="success"><h4>{hc}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(1)}>COMPLETAR</IonButton>;
      case "sexo":
        return sexo
          ? <IonLabel className="ion-text-end"><IonText color="success"><h4>{esquema.sexos.find((x: any) => x.id === sexo).nombre}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(2)}>COMPLETAR</IonButton>;
      case "edad":
        return edad
          ? <IonLabel className="ion-text-end"><IonText color="success"><h4>{edad}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(3)}>COMPLETAR</IonButton>;
      case "institucion":
        return institucion !== undefined
          ? (
            institucion === OTRA_INSTITUCION
              ? <IonLabel className="ion-text-end"><IonText color="success"><h6>{institucionOtra}</h6></IonText></IonLabel>
              : <IonLabel className="ion-text-end"><IonText color="success"><h6>{esquema.instituciones.find((x: any) => x.id === institucion).nombre}</h6></IonText></IonLabel>
          )
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(4)}>COMPLETAR</IonButton>;
      case "fecha":
        return fecha
          ? <IonLabel className="ion-text-end"><IonText color="success"><h4>{format(parse(fecha, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(5)}>COMPLETAR</IonButton>;
      case "modulos":
        return modulos.length
          ? <IonLabel className="ion-text-end"><IonText color="success"><h4>{modulos.length} módulo{modulos.length === 1 ? "" : "s"}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(6)}>COMPLETAR</IonButton>;
      case "urgencia":
        return urgencia
          ? <IonLabel className="ion-text-end"><IonText color="success"><h4>{urgencia === "P" ? "Programada" : "Urgencia"}</h4></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(7)}>COMPLETAR</IonButton>;
      case "rol":
        return rol
          ? <IonLabel className="ion-text-end"><IonText color="success"><h6>{esquema.roles.find((x: any) => x.id === rol).nombre}</h6></IonText></IonLabel>
          : <IonButton slot="end" color="danger" mode="ios" fill="clear" onClick={() => setPagina(8)}>COMPLETAR</IonButton>;
      case "notas":
        return notas
          ? <IonLabel className="ion-text-end"><IonText color="success"><h6>{notas.substring(0, 20)}...</h6></IonText></IonLabel>
          : <IonLabel className="ion-text-end"><IonText color="medium"><h6>Ninguna</h6></IonText></IonLabel>
      default:
        break;
    }
  };

  const [present] = useIonAlert();

  const onNewModulo = () => {
    setModuloActual(undefined);
    setShowCargaModulo(true);
  };

  const onCloseNuevoModulo = (nuevoModulo: any) => {
    // console.log("onCloseNuevoModulo", nuevoModulo);
    setShowCargaModulo(false);
    if (nuevoModulo) {
      if (moduloActual) {
        // EDICIÓN
        // console.log("onCloseNuevoModulo(EDICIÓN)");
        const lista = modulos.map((x: Modulo) => {
          if (x.id === nuevoModulo.id) {
            return nuevoModulo;
          }
          return x;
        });
        setModulos([...lista]);
      } else {
        // ALTA
        // console.log("onCloseNuevoModulo(ALTA)");
        setModulos([...modulos, nuevoModulo]);
      }
    }
  };

  const onEditModulo = (modulo: Modulo) => {
    // console.log("editarModulo", modulo);
    setModuloActual(modulo);
    setShowCargaModulo(true);
  };

  const onDeleteModulo = (modulo: Modulo) => {
    // console.log("onDeleteModulo", modulo);
    present({
      header: 'Borrar Módulo',
      message: `¿Realmente desea borrar este Módulo ${modulo.modulo}?`,
      buttons: [
        'Cancelar',
        { text: 'Sí, borrar', handler: () => deleteModulo(modulo) },
      ]
    });
  };

  const deleteModulo = (modulo: Modulo) => {
    // console.log("deleteModulo", modulo);
    const lista = modulos.filter((x: Modulo) => x.id !== modulo.id);
    // console.dir(modulos);
    // console.dir(lista);
    setModulos([...lista]);
  };

  return (
    <IonPage id="nueva-practica">
      <IonHeader translucent={false}>
        <IonToolbar color="primary">
          <IonButtons slot="start">
          </IonButtons>
          <IonTitle>
            {practicaActual ? "Editar" : "Nueva"}&nbsp;Práctica
          </IonTitle>
          <IonButtons slot="end">
            {
              practicaActual && (
                <IonButton onClick={guardar} size="small" fill="outline" shape="round">
                  Guardar
                </IonButton>
              )
            }
            <IonButton onClick={() => onDismissModal(null)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonProgressBar color="secondary" value={pagina / PAGINA_RESUMEN}></IonProgressBar>
        {
          pagina === 1 && (
            <IonList lines="full">
              <IonListHeader lines="full" color="tertiary">
                <IonLabel>HISTORIA CLÍNICA</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel position="stacked">Historia Clínica</IonLabel>
                <IonInput
                  value={hc}
                  onIonChange={e => setHc(e.detail.value!)}
                  placeholder="Ingrese la Historia Clínica"
                  enterkeyhint="done"
                  clearInput
                  ref={(ref) => inputRef.current = ref}
                ></IonInput>
              </IonItem>
            </IonList>
          )
        }

        {
          pagina === 2 && (
            <IonList lines="full">
              <IonRadioGroup value={sexo} onIonChange={e => {
                setSexo(e.detail.value);
                if (autoAvanzar && validarAvanceConValor(e.detail.value)) { avanzar(); }
              }}>
                <IonListHeader lines="full" color="tertiary">
                  <IonLabel>SEXO</IonLabel>
                </IonListHeader>
                {esquema.sexos.map((sexo: any) => (
                  <IonItem key={sexo.id}>
                    <IonLabel>{sexo.nombre}</IonLabel>
                    <IonRadio slot="end" value={sexo.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === 3 && (
            <IonList lines="full">
              <IonListHeader lines="full" color="tertiary">
                <IonLabel>EDAD</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel position="stacked">Edad</IonLabel>
                <IonInput
                  type="number"
                  value={edad}
                  inputMode="numeric"
                  enterkeyhint="done"
                  onIonChange={e => setEdad(parseInt(e.detail.value!))}
                  placeholder="Ingrese la Edad del paciente"
                  clearInput
                  min="0"
                  max="120"
                  step="1"
                >
                </IonInput>
              </IonItem>
            </IonList>
          )
        }

        {
          pagina === 4 && (
            <IonList mode="md" className="ion-no-padding">
              <IonListHeader lines="full" color="tertiary">
                <IonLabel>INSTITUCIÓN</IonLabel>
              </IonListHeader>
              <IonSegment
                mode="md"
                value={modoInstitucion}
                onIonChange={e => setModoInstitucion(e.detail.value as "origen" | "todas" | "otra")}
              >
                {
                  usuario?.id_institucion && (
                    <IonSegmentButton value="origen">
                      <IonLabel>Origen</IonLabel>
                    </IonSegmentButton>
                  )
                }
                <IonSegmentButton value="todas">
                  <IonLabel>Todas</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="otra">
                  <IonLabel>Otra</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              {
                modoInstitucion === "todas" && (
                  <IonSearchbar
                    mode="ios"
                    value={filtroInstitucion}
                    placeholder="Filtrar Instituciones..."
                    onIonChange={e => setFiltroInstitucion(e.detail.value!)}
                    showClearButton="always"
                    debounce={100}
                  >
                  </IonSearchbar>
                )
              }
              <IonRadioGroup
                value={institucion}
                onIonChange={e => {
                  setInstitucionOtra("");
                  setInstitucion(e.detail.value);
                  if (
                    autoAvanzar &&
                    validarAvanceConValor(e.detail.value)
                  ) {
                    avanzar();
                  }
                }}
              >
                {
                  modoInstitucion === "origen" && (
                    <IonItem key={usuario?.id_institucion}>
                      <IonLabel text-wrap>{usuario?.residencia}</IonLabel>
                      <IonRadio slot="end" value={usuario?.id_institucion} />
                    </IonItem>
                  )
                }
                {
                  modoInstitucion === "todas" && (
                    esquema.provincias.map((provincia: any) => armarProvincia(provincia))
                  )
                }
              </IonRadioGroup>
              {
                modoInstitucion === "otra" && (
                  <IonItem>
                    <IonTextarea
                      placeholder="Ingrese el nombre de la Institución o lugar donde se realizó la Práctica"
                      value={institucionOtra}
                      rows={3}
                      autoGrow
                      onIonChange={e => {
                        setInstitucionOtra(e.detail.value!);
                        setInstitucion(OTRA_INSTITUCION);
                      }}
                    >
                    </IonTextarea>
                  </IonItem>
                )
              }
            </IonList>
          )
        }

        {
          pagina === 5 && (
            <IonList lines="full">
              <IonListHeader lines="full" color="tertiary">
                <IonLabel>FECHA</IonLabel>
              </IonListHeader>
              <IonDatetime
                presentation="date"
                locale="es-AR"
                size="cover"
                value={fecha}
                firstDayOfWeek={1} // Lunes
                max={format(new Date(), "yyyy-MM-dd")}
                onIonChange={e => setFecha(e.detail.value!)}
              ></IonDatetime>
            </IonList>
          )
        }

        {
          pagina === 6 && (
            <>
              <IonList lines="full">
                <IonListHeader color="tertiary">
                  <IonLabel>MÓDULOS{modulos.length ? ` (${modulos.length})` : ''}</IonLabel>
                </IonListHeader>
                <IonListHeader lines="full" color="light">
                  <IonLabel>{modulos.length} {modulos.length === 1 ? 'módulo cargado' : 'módulos cargados'}</IonLabel>
                </IonListHeader>
                {modulos.map((modulo: Modulo) => (
                  <IonItem key={modulo.id}>
                    <IonLabel text-wrap>{modulo.modulo}</IonLabel>
                    <IonLabel text-wrap color="medium">{modulo.diagnostico}</IonLabel>
                    <IonButtons slot="end">
                      <IonButton onClick={() => onEditModulo(modulo)} color="primary">
                        <IonIcon slot="icon-only" icon={createOutline} />
                      </IonButton>
                      <IonButton onClick={() => onDeleteModulo(modulo)} color="danger">
                        <IonIcon slot="icon-only" icon={trashOutline} />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                ))}
              </IonList>
              <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton color="primary" onClick={onNewModulo}>
                  <IonIcon icon={add} />
                </IonFabButton>
              </IonFab>
            </>
          )
        }

        {
          pagina === 7 && (
            <IonList lines="full">
              <IonRadioGroup value={urgencia} onIonChange={e => {
                setUrgencia(e.detail.value);
                if (autoAvanzar && validarAvanceConValor(e.detail.value)) { avanzar(); }
              }}>
                <IonListHeader lines="full" color="tertiary">
                  <IonLabel>TIPO DE CIRUGÍA</IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonLabel>Programada</IonLabel>
                  <IonRadio slot="end" value="P" />
                </IonItem>
                <IonItem>
                  <IonLabel>Urgencia</IonLabel>
                  <IonRadio slot="end" value="U" />
                </IonItem>
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === 8 && (
            <IonList lines="full">
              <IonRadioGroup value={rol} onIonChange={e => {
                setRol(e.detail.value);
                if (autoAvanzar && validarAvanceConValor(e.detail.value)) { avanzar(); }
              }}>
                <IonListHeader lines="full" color="tertiary">
                  <IonLabel>ROL DENTRO DEL QUIRÓFANO</IonLabel>
                </IonListHeader>
                {esquema.roles.map((rol: any) => (
                  <IonItem key={rol.id}>
                    <IonLabel>{rol.nombre}</IonLabel>
                    <IonRadio slot="end" value={rol.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )
        }

        {
          pagina === 9 && (
            <IonList lines="full">
              <IonListHeader lines="full" color="tertiary">
                <IonLabel>NOTAS (opcional)</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonTextarea
                  placeholder="Notas de la Cirugía..."
                  value={notas}
                  rows={10}
                  autoGrow
                  onIonChange={e => setNotas(e.detail.value!)}
                >
                </IonTextarea>
              </IonItem>
            </IonList>
          )
        }

        {
          pagina === 10 && (
            <IonList lines="full">
              <IonListHeader lines="full" color="tertiary">
                <IonLabel>RESUMEN</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel>
                  Historia Clínica
                </IonLabel>
                {validarCampo('hc')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Sexo
                </IonLabel>
                {validarCampo('sexo')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Edad
                </IonLabel>
                {validarCampo('edad')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Institución
                </IonLabel>
                {validarCampo('institucion')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Fecha
                </IonLabel>
                {validarCampo('fecha')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Módulos
                </IonLabel>
                {validarCampo('modulos')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Tipo
                </IonLabel>
                {validarCampo('urgencia')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Rol en quirófano
                </IonLabel>
                {validarCampo('rol')}
              </IonItem>
              <IonItem>
                <IonLabel>
                  Notas
                </IonLabel>
                {validarCampo('notas')}
              </IonItem>
            </IonList>
          )
        }
      </IonContent>
      <IonFooter>
        <IonToolbar color="tertiary">
          <IonButtons slot="end">
            {
              pagina === PAGINA_RESUMEN
                ? (
                  <IonButton color="success" mode="md" fill="solid" onClick={guardar} disabled={!validarAvance()}>
                    Guardar
                    <IonIcon slot="end" icon={checkmarkOutline} />
                  </IonButton>
                ) : (
                  <IonButton onClick={avanzar} disabled={!validarAvance()}>
                    Siguiente
                    <IonIcon slot="end" icon={chevronForwardOutline} />
                  </IonButton>
                )
            }
          </IonButtons>
          {
            pagina > 1 && (
              <IonButtons slot="start">
                <IonButton onClick={retroceder}>
                  Anterior
                  <IonIcon slot="start" icon={chevronBackOutline} />
                </IonButton>
              </IonButtons>
            )
          }
        </IonToolbar>
      </IonFooter>
      <IonModal
        isOpen={showCargaModulo}
        onDidDismiss={() => setShowCargaModulo(false)}
        swipeToClose={true}
      >
        <NuevoModulo
          moduloActual={moduloActual}
          onDismissModal={onCloseNuevoModulo}
        />
      </IonModal>
    </IonPage>
  );
};

export default NuevaPracticaPage;
