import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonInput, IonIcon, IonTextarea, IonCol, IonGrid, IonRow, useIonAlert, IonNote, IonToggle } from '@ionic/react';
import { checkmarkOutline, closeOutline, trashOutline } from 'ionicons/icons';
import { DatePickerComponent, DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Controller, useForm } from 'react-hook-form';
import { Novedad } from '../models/Novedad';
import { CompactPicker } from 'react-color'
import './AbmNovedad.scss'
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';

interface AbmNovedadProps {
  item: Novedad | undefined,
  onDismissModal: (novedad: Novedad | undefined) => void;
  onRequestDelete: (novedad: Novedad) => void;
}

const AbmNovedadPage: React.FC<AbmNovedadProps> = ({ item, onDismissModal, onRequestDelete }) => {
 
  const [present] = useIonAlert();

  const onDelete = () => {
    if (item) {
      present({
        header: 'Confirmación',
        message: '¿Desea borrar esta Novedad?',
        buttons: [
          'Cancelar',
          { text: 'Borrar', handler: (d) => onRequestDelete(item) },
        ]
      });
    }
  };

  const { control, watch, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      id: item?.id || undefined,
      titulo: item?.titulo || "",
      subtitulo: item?.subtitulo || "",
      texto: item?.texto || "",
      link: item?.link || "",
      imagen: item?.imagen || "",
      color: item?.color || "#000000",
      fondo: item?.fondo || "#FFFFFF",
      fecha: item?.fecha ? new Date(parseISO(item.fecha)?.toUTCString()) : new Date(),
      rango: [ 
        item?.desde ? new Date(parseISO(item.desde)?.toUTCString()) : startOfMonth(new Date()),
        item?.hasta ? new Date(parseISO(item.hasta)?.toUTCString()) : endOfMonth(new Date())
      ],
      fijado: item?.fijado || false
    }
  });

  const onSubmit = (data: any) => {
    const novedad = {
      id: data.id || null,
      titulo: data.titulo || null,
      subtitulo: data.subtitulo || null,
      texto: data.texto || null,
      link: data.link || null,
      imagen: data.imagen || null,
      color: data.color || null,
      fondo: data.fondo || null,
      fecha: data.fecha ? format(data.fecha, "yyyy-MM-dd") : undefined,
      desde: data.rango && data.rango[0] ? format(data.rango[0], "yyyy-MM-dd") : undefined,
      hasta: data.rango && data.rango[1] ? format(data.rango[1], "yyyy-MM-dd") : undefined,
      fijado: data.fijado
    };
    if (!item) {
      delete novedad.id;
    }
    onDismissModal(novedad);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage id="abm-novedad">
        <IonHeader translucent={false}>
          <IonToolbar color="primary">
            <IonButtons slot="start">
            </IonButtons>
            <IonTitle>
              {item ? "Editar" : "Nueva"}&nbsp;Novedad
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismissModal(undefined)}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList lines="full">
            <IonItem className={errors.titulo ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Título</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el Título"
                    // clearInput
                  />
                )}
                control={control}
                name="titulo"
                rules={{
                  required: {
                    value: true,
                    message: "Campo requerido"
                  },
                  minLength: {
                    value: 5,
                    message: "Mínimo 5 caracteres"
                  }
                }}
              />
              {/* <IonNote slot="helper">Helper Text</IonNote> */}
              <IonNote slot="error">{errors?.titulo?.message}</IonNote>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Subtítulo</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el Subtítulo"
                    // clearInput
                  />
                )}
                control={control}
                name="subtitulo"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Texto</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonTextarea
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el texto de la novedad"
                    rows={3}
                    autoGrow
                  />
                )}
                control={control}
                name="texto"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Enlace</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el enlace"
                    // clearInput
                  />
                )}
                control={control}
                name="link"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Imagen</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese la URL de la imagen"
                    // clearInput
                  />
                )}
                control={control}
                name="imagen"
              />
            </IonItem>
            {/* https://ej2.syncfusion.com/react/documentation/datepicker/getting-started/ */}
            {/* https://stackoverflow.com/a/67995336/18373442 */}
            <IonItem lines="none">
              <IonLabel position="fixed">
                Fecha
              </IonLabel>
              <Controller
                render={({ field }) => (
                  <DatePickerComponent
                    {...field}
                    locale={"es"}
                    value={field.value}
                    format="dd/MM/yyyy"
                    delayUpdate={true}
                    onChange={(args: any) => field.onChange(args.value ? args.value : undefined)}
                  />
                )}
                control={control}
                name="fecha"
                rules={{
                  required: {
                    value: true,
                    message: "Fecha requerida"
                  }
                }}
              />
              <IonNote slot="error">{errors?.fecha?.message}</IonNote>
            </IonItem>
            {/* https://ej2.syncfusion.com/react/documentation/datepicker/date-format/ */}
            <IonItem>
              <IonLabel position="fixed">
                Validez
              </IonLabel>
              <Controller
                render={({ field }) => (
                  <DateRangePickerComponent
                    {...field}
                    locale={"es"}
                    startDate={field.value[0]}
                    endDate={field.value[1]}
                    format="dd/MM/yyyy"
                    delayUpdate={true}
                    separator=" » "
                    onChange={(args: any) => field.onChange(args.value ? args.value : undefined)}
                  />
                )}
                control={control}
                name="rango"
                rules={{
                  required: {
                    value: true,
                    message: "Rango de Fechas requerido"
                  }
                }}                
              />
              <IonNote slot="error">{(errors?.rango as any)?.message}</IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>¿Fijar en la parte superior?</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonToggle
                    slot="end"
                    checked={field.value}
                    onIonChange={e => field.onChange(e.detail.checked)}
                  />
                )}
                control={control}
                name="fijado"
              />
            </IonItem>            
            {/* http://casesandberg.github.io/react-color/ */}
            <IonItem style={{ paddingBottom: 10 }} lines="none">
              <IonLabel position="floating">
                Colores
              </IonLabel>
              <IonGrid no-padding>
                <IonRow no-padding >
                  <IonCol>
                    <div style={{
                      color: watch("color"),
                      backgroundColor: watch("fondo"),
                      textAlign: "center",
                      padding: "5px 0px !important",
                      margin: "0px 10px !important",
                      boxShadow: "rgb(0 0 0 / 12%) 0px 2px 10px, rgb(0 0 0 / 16%) 0px 2px 5px",
                      borderRadius: "2px"
                    }}>
                      EJEMPLO
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow no-padding>
                  <IonCol no-padding>
                    <Controller
                      render={({ field }) => (
                        <CompactPicker
                          color={field.value}
                          onChangeComplete={(e) => field.onChange(e.hex)}
                        />
                      )}
                      control={control}
                      name="color"
                    />
                  </IonCol>
                </IonRow>
                <IonRow no-padding >
                  <IonCol no-padding>
                    <Controller
                      render={({ field }) => (
                        <CompactPicker
                          color={field.value}
                          onChangeComplete={(e) => field.onChange(e.hex)}
                        />
                      )}
                      control={control}
                      name="fondo"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar color="tertiary">
            {
              item && (
                <IonButtons slot="start">
                  <IonButton onClick={onDelete}>
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                </IonButtons>
              )
            }
            <IonButtons slot="end">
              <IonButton color="success" mode="md" fill="solid" type="submit" disabled={!isValid}>
                Guardar
                <IonIcon slot="end" icon={checkmarkOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default AbmNovedadPage;
