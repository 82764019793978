import React from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton } from '@ionic/react';
import Contacto from './Contacto';
import './ContactoPagina.scss';

const ContactoPagina: React.FC = () => {
  return (
    <IonPage id="contacto-pagina-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <Contacto />
      </IonContent>
    </IonPage>
  );
};

export default React.memo(ContactoPagina);
