import React from 'react';
import {
  ColumnDirective, ColumnsDirective,
  GridComponent, Inject, Page, Sort,
  CommandColumn, Toolbar, ExcelExport
} from '@syncfusion/ej2-react-grids';
import './GrillaDeBanners.css';

const GrillaDeBanners = ({ data, onEdit }) => {

  // https://ej2.syncfusion.com/react/documentation/grid/excel-export/excel-exporting/
  // https://ej2.syncfusion.com/react/documentation/grid/tool-bar/tool-bar-items/

  let grid = null;
  
  const toolbarOptions = [
    { text: 'Exportar', tooltipText: 'Exportar a Excel', prefixIcon: 'e-excelexport', id: 'excel-export' }
  ];

  const toolbarClick = (args) => {
    if (grid && args.item.id === "excel-export") {
      const excelExportProperties = {
        fileName: "banners.xlsx"
      };
      grid.excelExport(excelExportProperties);
    }
  };

  const comandos = [
    { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }
  ];

  const commandClick = (args) => {
    const item = args.rowData;
    const tipo = args.commandColumn.type;
    if (tipo === "Edit") {
      onEdit(item);
    }
  };

  // <img src="/images/no-photos.svg" alt="" style={{ maxWidth: 80 }} />
  // const getImagen = (args) => (
  //   args?.imagen1 !== null
  //   ? <img src={args.imagen1} alt="" style={{ maxWidth: 80 }} />
  //   : null
  // );

  return (
    <GridComponent
      dataSource={data}
      locale={"es"}
      allowPaging
      allowSorting
      ref={g => grid = g}
      toolbar={toolbarOptions}
      allowExcelExport={true}
      toolbarClick={toolbarClick}      
      pageSettings={{ pageSize: 10, pageCount: 3 }}
      commandClick={commandClick}
    >
        <ColumnsDirective>
          {/* TODO Da error */}
          {/* <ColumnDirective
            headerText="Imagen"
            template={getImagen}
            width="80"
          /> */}
          <ColumnDirective field="id" headerText="Id" visible={false} isPrimaryKey width={100} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="ubicacion" headerText="Ubicación" width={100} clipMode="EllipsisWithTooltip" />
          <ColumnDirective field="imagen1" headerText="Imagen" headerTextAlign="Left" textAlign="Left" width={200} />
          <ColumnDirective headerText="" width="75" commands={comandos}></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Page, Sort, CommandColumn, Toolbar, ExcelExport]} />
    </GridComponent>
  );
};

export default GrillaDeBanners;
