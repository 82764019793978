export const AAOT_WEB = "https://aaot.org.ar/residencias/residencias-acreditadas/";
export const AAOT_MAPA = "https://www.google.com/maps/place/Asociaci%C3%B3n+Argentina+de+Ortopedia+y+Traumatolog%C3%ADa/@-34.5903292,-58.3917544,15z/data=!4m5!3m4!1s0x0:0x45dcae1a658dbf1f!8m2!3d-34.5903339!4d-58.3917602";
export const AAOT_MAIL = "residencias@aaot.org.ar";
export const AAOT_TEL  = "tel:+541148012320";
export const AAOT_WHATSAPP = "https://web.whatsapp.com/send?phone=5491134252320&text=";
export const AAOT_FACEBOOK = "https://www.facebook.com/AAOTorg/";
export const AAOT_TWITTER = "https://twitter.com/aaotorg";
export const AAOT_YOUTUBE = "https://www.youtube.com/c/AAOTorgok";
export const AAOT_INSTAGRAM = "https://www.instagram.com/aaotorg/";
export const AAOT_LINKEDIN = "https://www.linkedin.com/company/aaotorg";

export const OTRA_INSTITUCION = 1;
export const PASSWORD_LARGO = 4;
export const CODIGO_LARGO = 4;
export const EDITABLE_HACIA_ATRAS_CANTIDAD = 48;
export const EDITABLE_HACIA_ATRAS_UNIDAD = "horas";
