import React, { useState } from 'react';
import './ListaDePracticas.scss';
import { closeOutline, createOutline, informationCircleOutline, listOutline, timeOutline, trashOutline } from 'ionicons/icons';
import { IonToolbar, IonButton, IonIcon, IonList, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel, IonTitle, IonButtons, IonChip } from '@ionic/react';
import { Practica } from '../models/Practica';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale'
import { EDITABLE_HACIA_ATRAS_CANTIDAD, EDITABLE_HACIA_ATRAS_UNIDAD } from '../constantes';

interface ListaDePracticasProps {
  data: Practica[];
  onEdit: (practica: Practica) => void;
  onDelete: (practica: Practica) => void;
}

const ListaDePracticas: React.FC<ListaDePracticasProps> = ({ data, onEdit, onDelete }) => {

  const [showChip, setShowChip] = useState(true);

  const armarSexo = (sexo: string): string => {
    if (sexo === "F") {
      return " femenino";
    }
    if (sexo === "M") {
      return " masculino";
    }
    return "";
  }

  return (
    <>
      <IonToolbar color="tertiary" mode="md">
        <IonTitle>
          {`Prácticas editables (últimas ${EDITABLE_HACIA_ATRAS_CANTIDAD} ${EDITABLE_HACIA_ATRAS_UNIDAD})`}
        </IonTitle>
      </IonToolbar>
      {
        showChip && (
          <IonChip onClick={() => setShowChip(false)} className="mensaje mensaje--info">
            <IonIcon icon={informationCircleOutline} color="primary" />
            <IonLabel>
              {`Pasadas las ${EDITABLE_HACIA_ATRAS_CANTIDAD} ${EDITABLE_HACIA_ATRAS_UNIDAD}, sólo es posible consultar las Prácticas desde Estadísticas`}
            </IonLabel>
            <IonIcon icon={closeOutline} />
          </IonChip>
        )
      }
      <IonList no-padding id="lista-de-practicas">
        {
          data.map((practica: Practica) => (
            <IonCard key={practica.id} mode="md">
              <IonCardHeader>
                <IonCardSubtitle>
                  {practica.fecha}
                </IonCardSubtitle>
                <IonCardTitle>
                  {practica.institucion} ({practica.provincia})
                </IonCardTitle>
              </IonCardHeader>
              <IonItem no-padding>
                <IonIcon icon={timeOutline} slot="start" />
                <IonLabel no-padding>
                  {/* https://bit.cloud/date-fns/date-fns/format-distance-strict */}
                  {formatDistanceStrict(0, (practica.minutos || 0) * 60 * 1000, { locale: es, addSuffix: true })}
                </IonLabel>
                <IonButtons slot="end">
                  <IonButton onClick={() => onEdit(practica)} color="primary">
                    <IonIcon slot="icon-only" icon={createOutline} />
                  </IonButton>
                  <IonButton onClick={() => onDelete(practica)} color="danger">
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                </IonButtons>
              </IonItem>
              <IonItem>
              {
                `Práctica #${practica.id} ${practica.urgencia?.toLowerCase()} con Historia Clínica ${practica.hc}, realizada a un paciente${armarSexo(practica.sexo || "")} de ${practica.edad} año${practica.edad === 1 ? "" : "s"}, en un rol de ${practica.rol}.`
              }
              </IonItem>
              {
                practica.notas && <IonCardContent>{practica.notas}</IonCardContent>
              }
              <IonItem lines="none">
                <IonIcon icon={listOutline} slot="start" />
                <IonLabel>{`${practica.total_modulos} módulo${practica.total_modulos === 1 ? "" : "s"}`}</IonLabel>
              </IonItem>
            </IonCard>
          ))
        }
      </IonList>
    </>
  );
};

export default ListaDePracticas;
