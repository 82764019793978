import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonInput, IonIcon, useIonAlert, IonBadge } from '@ionic/react';
import { closeOutline, lockClosedOutline, lockOpenOutline } from 'ionicons/icons';
import { Usuario } from '../models/Usuario';
import './AbmUsuario.scss'
import { format, parse } from 'date-fns';

interface AbmUsuarioProps {
  item: Usuario | undefined,
  onDismissModal: () => void;
  onRequestActivo: (usuario: Usuario, activo: boolean) => void;
}

const AbmUsuarioPage: React.FC<AbmUsuarioProps> = ({ item, onDismissModal, onRequestActivo }) => {
 
  const [present] = useIonAlert();

  const onBloquear = () => {
    if (!item) {
      return;
    }
    const accion = item?.activo ? "Bloquear" : "Desbloquear";
    present({
      header: 'Confirmación',
      message: `¿${accion} a este Residente?`,
      buttons: [
        'Cancelar',
        { text: 'Borrar', handler: (d) => onRequestActivo(item, !item?.activo) },
      ]
    });
  };

  return (
    <IonPage id="abm-usuario">
      <IonHeader translucent={false}>
        <IonToolbar color="primary">
          <IonButtons slot="start">
          </IonButtons>
          <IonTitle>
            Información del Usuario
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismissModal}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines="full">
          <IonItem>
            <IonLabel position="stacked">DNI</IonLabel>
              <IonInput
                value={item?.id}
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">ID INTEC</IonLabel>
              <IonInput
                value={item?.id_cliente}
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Nombre</IonLabel>
              <IonInput
                value={item?.nombre}
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Nombre Completo</IonLabel>
              <IonInput
                value={item?.nombre_completo}
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                value={item?.email}
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Clasificación</IonLabel>
              <IonInput
                value={`${item?.id_clasificacion} » ${item?.clasificacion}`}
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Residencia</IonLabel>
              <IonInput
                value={item?.id_institucion
                  ? `${item?.provincia} » ${item?.residencia}`
                  : "(NO SELECCIONADA)"
                }
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Fecha de Nacimiento</IonLabel>
              <IonInput
                value={
                  item?.fecha_nacimiento
                  ? format(parse(item?.fecha_nacimiento, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")
                  : ''
                }
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Fecha de Ingreso</IonLabel>
              <IonInput
                value={
                  item?.fecha_ingreso
                  ? format(parse(item?.fecha_ingreso, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")
                  : ''
                }
                disabled
              />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Fecha de Egreso</IonLabel>
              <IonInput
                value={
                  item?.fecha_egreso
                  ? format(parse(item?.fecha_egreso, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")
                  : ''
                }
                disabled
              />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar color="tertiary">
          <IonLabel>
            {
              item?.activo
              ? <IonBadge color="success">ACTIVO</IonBadge>
              : <IonBadge color="danger">NO ACTIVO</IonBadge>
            }
          </IonLabel>
          <IonButtons slot="end">
            {
              item?.activo
              ? (
                <IonButton onClick={onBloquear}>
                  Bloquear
                  <IonIcon slot="end" icon={lockClosedOutline} />
                </IonButton>
              ) : (
                <IonButton onClick={onBloquear}>
                  Desbloquear
                  <IonIcon slot="end" icon={lockOpenOutline} />
                </IonButton>
              )
            }
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default AbmUsuarioPage;
