import React, { useContext, useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonFooter, IonIcon, IonPage, IonItem, IonLabel, IonModal, IonSelect, IonSelectOption, IonInput } from '@ionic/react';
import { checkmarkOutline, closeOutline, trashOutline } from 'ionicons/icons';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import SelectorDeInstitucion from './SelectorDeInstitucion';
import { Institucion } from '../models/Institucion';
import { Provincia } from '../models/Provincia';
import './PracticasFiltros.scss'
import { AppContext } from '../AppContext';
import { Sexo } from '../models/Sexo';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ModuloSimple } from '../models/ModuloSimple';
import { RolQuirofano } from '../models/RolQuirofano';
import SelectorDeResidente from './SelectorDeResidente';
import { UsuarioSimple } from '../models/UsuarioSimple';
import { defaultFiltros, Filtros } from '../models/Filtros';

//--------------------
// FILTROS DISPONIBLES
//--------------------

// GLOBALES

// - 01) Rango de Fechas
// - 02) Provincia
// - 03) Institución
// - 04) Sexo
// - 05) Rango de Edades
// - 06  Módulo
// - 07) TIPO DE CIRUGÍA
// - 08) ROL DENTRO DEL QUIRÓFANO

// ESPECÍFICOS

// - 09) Médico Residente
// - 10) Historia Clínica

// MUY ESPECÍFICOS

// Toda la jerarquía???:
//  Topografía
//  Etiología
//  Diagnóstico
//  Tratamiento

interface PracticasFiltrosProps {
  onDismissModal: (nuevosFiltros: Filtros | null) => void;
  filtros: Filtros;
}

const PracticasFiltros: React.FC<PracticasFiltrosProps> = ({ onDismissModal, filtros }) => {

  const [desde, setDesde] = useState<Date | undefined>(filtros.desde);
  const [hasta, setHasta] = useState<Date | undefined>(filtros.hasta);
  const [provincia, setProvincia] = useState<number>(filtros.provincia);  
  const [institucion, setInstitucion] = useState<Institucion | null>(filtros.institucion);
  const [sexo, setSexo] = useState<string>(filtros.sexo);
  const [edades, setEdades] = useState(filtros.edades);
  const [modulo, setModulo] = useState<string>(filtros.modulo);
  const [urgencia, setUrgencia] = useState<string>(filtros.urgencia);
  const [rolQuirofano, setRolQuirofano] = useState<number>(filtros.rolQuirofano);
  
  const [residente, setResidente] = useState<UsuarioSimple | null>(null);
  const [hc, setHc] = useState<string>("");

  const [showInstitucion, setShowInstitucion] = useState(false);
  const [showResidente, setShowResidente] = useState(false);

  const {
    esquema,
    usuario
  } = useContext(AppContext);

  const guardarInstitucion = (ins: Institucion) => {
    setShowInstitucion(false);
    setInstitucion(ins);
  };

  const guardarResidente = (res: UsuarioSimple | null) => {
    setShowResidente(false);
    setResidente(res);
  };

  // https://www.syncfusion.com/forums/172683/error-unstable-flushdiscreteupdates-cannot-flush-updates-when-react-is-already-rendering-if

  const onCambioRango = (args: any) => {
    if (args.value) {
      setDesde(args.value[0]);
      setHasta(args.value[1]);
    } else {
      setDesde(undefined);
      setHasta(undefined);
    }
  }

  const onClear = () => {
    if (usuario) {
      const valores = defaultFiltros(usuario);
      setDesde(valores.desde);
      setHasta(valores.hasta);
      setProvincia(valores.provincia);
      setInstitucion(valores.institucion);
      setSexo(valores.sexo);
      setEdades(valores.edades);
      setModulo(valores.modulo);
      setUrgencia(valores.urgencia);
      setRolQuirofano(valores.rolQuirofano);
      setResidente(valores.residente);
      setHc(valores.hc);      
    }
  };

  const onGuardar = () => {
    let nuevosFiltros = {
      desde: desde,
      hasta: hasta,
      provincia: provincia,
      institucion: institucion,
      sexo: sexo,
      edades: edades,
      modulo: modulo,
      urgencia: urgencia,
      rolQuirofano: rolQuirofano,
      residente: residente,
      hc: hc
    };
    onDismissModal(nuevosFiltros);
  };

  return (
    <IonPage id="filtros-page">
      <IonHeader translucent={true} no-border className="filtros-practicas">
        <IonToolbar color="primary">
          <IonTitle>
            Filtros
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismissModal(null)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="filtros-practicas">
        <IonList lines="full">
          {/* <IonItem>
            <IonLabel>
              <p>Desde: {desde?.toUTCString()}</p>
              <p>Hasta: {hasta?.toUTCString()}</p>
              <p>Provincia: {provincia ? esquema.provincias.find((x: Provincia) => x.id === provincia)?.nombre : "(TODAS)"}</p>
              <p>Institución: {institucion?.nombre || "(TODAS)"}</p>
              <p>Sexo: {sexo || "(TODOS)"}</p>
              <p>Edades: De: {edades[0]} a {edades[1]}</p>
              <p>Módulo: {modulo || "(TODOS)"}</p>
              <p>Urgencia: {urgencia === "P" ? "Programada" : (urgencia === "U" ? "Urgencia" : "(TODAS)")}</p>
              <p>Rol Quirófano: {rolQuirofano ? esquema.roles.find((x: RolQuirofano) => x.id === rolQuirofano)?.nombre : "(TODOS)"}</p>
              <p>Residente: {residente?.nombre_completo || "(TODOS)"}</p>
              <p>HC: {hc || "(TODAS)"}</p>
            </IonLabel>
          </IonItem> */}
          <IonItem lines="none">
            <IonLabel position="fixed">
              Período
            </IonLabel>
            <DateRangePickerComponent
              locale={"es"}
              startDate={desde}
              endDate={hasta}
              format="dd/MM/yyyy"
              separator=" » "
              delayUpdate={true}
              onChange={onCambioRango}
            />
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Provincia</h2>
            </IonLabel>
            <IonSelect
              interfaceOptions={{ header: 'Provincia' }}
              interface="alert"
              multiple={false}
              onIonChange={e => setProvincia(e.detail.value)}
              value={provincia}
              cancelText="Cerrar"
            >
              <IonSelectOption value={0}>(TODAS)</IonSelectOption>
              {
                esquema.provincias.map((x: Provincia) => (
                  <IonSelectOption
                    key={x.id}
                    value={x.id}
                  >
                    {x.nombre}
                  </IonSelectOption>
                ))
              }
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Institución</h2>
              <p className="gris">{
                institucion
                  ? institucion.nombre
                  : '(TODAS)'
              }</p>
              {
                institucion && (
                  <small className="gris">{institucion.provincia}</small>
                )
              }
            </IonLabel>
            <IonButton fill="outline" slot="end" onClick={() => setShowInstitucion(true)}>
              Seleccionar
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Sexo</h2>
            </IonLabel>
            <IonSelect
              interfaceOptions={{ header: 'Sexo' }}
              interface="alert"
              multiple={false}
              onIonChange={e => setSexo(e.detail.value)}
              value={sexo}
              cancelText="Cerrar"
            >
              <IonSelectOption value={""}>(TODOS)</IonSelectOption>
              {
                esquema.sexos.map((x: Sexo) => (
                  <IonSelectOption
                    key={x.id}
                    value={x.id}
                  >
                    {x.nombre}
                  </IonSelectOption>
                ))
              }
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="fixed">
              <h2>Edades</h2>
              <p>{edades[0]}-{edades[1]}</p>
            </IonLabel>
            <Slider
              range
              min={0}
              max={120}
              marks={{
                0: '0',
                120: '120'
              }}
              step={1}
              onChange={(e) => setEdades(e as number[])}
              defaultValue={[0, 120]}
              value={edades}
              allowCross={false}
              style={{ marginRight: 15 }}
            />
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Módulo</h2>
            </IonLabel>
            <IonSelect
              interfaceOptions={{ header: 'Módulo' }}
              interface="alert"
              multiple={false}
              onIonChange={e => setModulo(e.detail.value)}
              value={modulo}
              cancelText="Cerrar"
            >
              <IonSelectOption value={""}>(TODOS)</IonSelectOption>
              {
                esquema.modulos.map((x: ModuloSimple) => (
                  <IonSelectOption
                    key={x.id}
                    value={x.id}
                  >
                    {x.nombre}
                  </IonSelectOption>
                ))
              }
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Tipo de Cirugía</h2>
            </IonLabel>
            <IonSelect
              interfaceOptions={{ header: 'Tipo de Cirugía' }}
              interface="alert"
              multiple={false}
              onIonChange={e => setUrgencia(e.detail.value)}
              value={urgencia}
              cancelText="Cerrar"
            >
              <IonSelectOption value={""}>(TODAS)</IonSelectOption>
              <IonSelectOption value={"P"}>Programada</IonSelectOption>
              <IonSelectOption value={"U"}>Urgencia</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Rol quirófano</h2>
            </IonLabel>
            <IonSelect
              interfaceOptions={{ header: 'Rol en el quirófano' }}
              interface="alert"
              multiple={false}
              onIonChange={e => setRolQuirofano(e.detail.value)}
              value={rolQuirofano}
              cancelText="Cerrar"
            >
              <IonSelectOption value={0}>(TODOS)</IonSelectOption>
              {
                esquema.roles.map((x: RolQuirofano) => (
                  <IonSelectOption
                    key={x.id}
                    value={x.id}
                  >
                    {x.nombre}
                  </IonSelectOption>
                ))
              }
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Residente</h2>
              <p className="gris">{
                residente
                  ? residente.nombre_completo
                  : '(TODOS)'
              }</p>
              {
                residente && (
                  <small className="gris">{residente?.email}</small>
                )
              }
            </IonLabel>
            <IonButton fill="outline" slot="end" onClick={() => setShowResidente(true)}>
              Seleccionar
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Historia Clínica</IonLabel>
            <IonInput
              value={hc}
              onIonChange={e => setHc(e.detail.value!)}
              placeholder="Ingrese la Historia Clínica"
              enterkeyhint="done"
              clearInput
            ></IonInput>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonButton onClick={onClear}>
              Limpiar
              <IonIcon slot="start" icon={trashOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="success" mode="md" fill="solid" onClick={onGuardar}>
              Aplicar
              <IonIcon slot="end" icon={checkmarkOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <IonModal
        isOpen={showInstitucion}
        swipeToClose={true}
      >
        <SelectorDeInstitucion
          onDismissModal={guardarInstitucion}
          onClose={() => setShowInstitucion(false)}
        />
      </IonModal>
      <IonModal
        isOpen={showResidente}
        swipeToClose={true}
      >
        <SelectorDeResidente
          onDismissModal={guardarResidente}
        />
      </IonModal>
    </IonPage>
  );
};

export default PracticasFiltros;
