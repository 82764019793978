import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonInput, IonIcon, useIonAlert, IonNote, IonToggle, IonSelect, IonSelectOption } from '@ionic/react';
import { checkmarkOutline, closeOutline, trashOutline } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { Institucion } from '../models/Institucion';
import { Provincia } from '../models/Provincia';
import './AbmInstitucion.scss'

interface AbmInstitucionProps {
  item: Institucion | undefined,
  provincias: Provincia[],
  onDismissModal: (ins: Institucion | undefined) => void;
  onRequestDelete: (ins: Institucion) => void;
}

const AbmInstitucionPage: React.FC<AbmInstitucionProps> = ({ item, provincias, onDismissModal, onRequestDelete }) => {

  const [present] = useIonAlert();

  const onDelete = () => {
    if (item) {
      present({
        header: 'Confirmación',
        message: '¿Borrar esta Institución?',
        buttons: [
          'Cancelar',
          { text: 'Borrar', handler: (d) => onRequestDelete(item) },
        ]
      });
    }
  };

  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      id: item?.id || undefined,
      id_provincia: item?.id_provincia || undefined,
      nombre: item?.nombre || "",
      activo: item ? item.activo : true
    }
  });

  const onSubmit = (data: any) => {
    const ins: Institucion = {
      id: data.id,
      nombre: data.nombre,
      id_provincia: data.id_provincia,
      provincia: "",
      activo: data.activo
    };
    onDismissModal(ins);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage id="abm-institucion">
        <IonHeader translucent={false}>
          <IonToolbar color="primary">
            <IonButtons slot="start">
            </IonButtons>
            <IonTitle>
              {item ? "Editar" : "Nueva"}&nbsp;Institución
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismissModal(undefined)}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList lines="full">
            <IonItem>
              <IonLabel>
                <h2>Provincia</h2>
              </IonLabel>
              <Controller
                render={({ field }) => (
                  <IonSelect
                    {...field}
                    interfaceOptions={{ header: 'Provincia' }}
                    interface="alert"
                    multiple={false}
                    onIonChange={e => field.onChange(e.detail.value || undefined)}
                    value={field.value}
                    cancelText="Cerrar"
                  >
                    {
                      provincias.map((x: Provincia) => (
                        <IonSelectOption
                          key={x.id}
                          value={x.id}
                        >
                          {x.nombre}
                        </IonSelectOption>
                      ))
                    }
                  </IonSelect>
                )}
                control={control}
                name="id_provincia"
                rules={{
                  required: {
                    value: true,
                    message: "Provincia requerida"
                  }
                }}
              />
            </IonItem>
            <IonItem className={errors.nombre ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Nombre</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    autocomplete="off"
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el Nombre de la Institución"
                  />
                )}
                control={control}
                name="nombre"
                rules={{
                  required: {
                    value: true,
                    message: "Nombre requerido"
                  }
                }}
              />
              <IonNote slot="error">{errors?.nombre?.message}</IonNote>
            </IonItem>
            <IonItem>
              <IonLabel position="fixed">Activo</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonToggle
                    slot="end"
                    checked={field.value}
                    onIonChange={e => field.onChange(e.detail.checked)}                    
                  />
                )}
                control={control}
                name="activo"
              />
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar color="tertiary">
            {
              item && (
                <IonButtons slot="start">
                  <IonButton onClick={onDelete}>
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                </IonButtons>
              )
            }
            <IonButtons slot="end">
              <IonButton color="success" mode="md" fill="solid" type="submit" disabled={!isValid}>
                Guardar
                <IonIcon slot="end" icon={checkmarkOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default AbmInstitucionPage;
