import React from 'react';
import { IonPage, IonSpinner } from '@ionic/react';
import './Loader.css'

const Loader: React.FC = () => {
  return (
    <IonPage className="spinnerCenter">
      <IonSpinner color="light" name="crescent" />
      <p>Iniciando...</p>
    </IonPage>
  );
};

export default Loader;
