import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import { AppContext } from '../AppContext';

const RedirectToLogin: React.FC = () => {
  const ionRouterContext = useContext(IonRouterContext);
  const {
    setToken,
    setUsuario
  } = useContext(AppContext);  
  useEffect(() => {
    setToken(undefined);
    setUsuario(undefined);
    ionRouterContext.push('/');
  }, [setToken, setUsuario, ionRouterContext]);
  return null;
};

export default RedirectToLogin;
