import React, { useState, useEffect, useContext } from 'react';
import { IonToolbar, IonContent, IonPage, IonButtons, IonTitle, IonMenuButton, IonIcon, IonToast, IonModal, IonHeader, IonChip, IonLabel, IonSpinner, IonLoading } from '@ionic/react';
import { closeOutline, alertCircleOutline } from 'ionicons/icons';
import axios from 'axios';
import GrillaDeBanners from '../components/GrillaDeBanners';
import { Banner } from '../models/Banner';
import LogoAaot from '../components/LogoAaot';
import AbmBanner from './AbmBanner';
import './Banners.scss'
import Log from '../components/Log';
import { AppContext } from '../AppContext';

const BannersPage: React.FC = () => {

  const [item, setItem] = useState<Banner | undefined>();
  const [showAbm, setShowAbm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState("");  
  const [error, setError] = useState("");
  const [banners, setBanners] = useState<Banner[]>([]);
  const [showMsg, setShowMsg] = useState("");

  const {
    usuario
  } = useContext(AppContext);
  
  const cargarBanners = () => {
    setError("");
    setLoader(true);
    const url = `${process.env.REACT_APP_API_URL}/banners`;
    axios.get(url)
      .then((res) => {
        setBanners(res.data);
        setLoader(false);
      }).catch((err) => {
        Log.error(
          err,
          usuario,
          `Banners.tsx » cargarBanners()`
        );
        const msg = err && err.response && err.response.data
          ? err.response.data.message
          : 'Error al intentar esta operación';
        setError(msg);
        setLoader(false);
      });
  }

  useEffect(() => {
    cargarBanners();
  // eslint-disable-next-line
  }, []);

  const onEdit = (banner: Banner) => {
    setItem(banner);
    setShowAbm(true);
  };

  const onCloseAbm = (banner: Banner | undefined) => {
    setShowAbm(false);
    setShowAbm(false);
    if (banner) {
      setLoading("Guardando banner...");
      axios.put(
        encodeURI(`${process.env.REACT_APP_API_URL}/banners/${banner.id}`), banner)
        .then(async () => {
          setError("");
          setLoading("");
          cargarBanners();
          setShowMsg("Banner editado");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Banners.tsx » onCloseAbm(): Error al intentar editar el Banner: ${JSON.stringify(banner)}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  const convertBlobToFile = async (url: any, fileName: string) => {
    let blob = await fetch(url).then(r => r.blob());
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  }

  const uploadFoto = async (ubicacion: string, archivo: string) => {
    // console.log("uploadFoto", ubicacion, archivo);
    setError("");
    setLoading("Guardando foto...");
    const file = await convertBlobToFile(archivo, ubicacion);
    let formData = new FormData();
    formData.append("ubicacion", ubicacion);
    formData.append("foto", file, ubicacion);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/banners/foto`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async () => {
        setLoading("");
      })
      .catch((err) => {
        Log.error(
          err,
          usuario,
          `Banners.tsx » uploadFoto(): Error al intentar subir una Foto a la Ubicación ${ubicacion} desde el Archivo=${archivo}`
        );
        const msg = err && err.response && err.response.data
          ? err.response.data.message
          : 'Error al intentar esta operación';
        setError(msg);
        setLoading("");
      });
  };

  return (
    <IonPage id="banners-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Banners de sponsors</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        {
          error && (
            <IonChip color="danger" onClick={() => setError("")}>
              <IonIcon icon={alertCircleOutline} color="primary" />
              <IonLabel>{error}</IonLabel>
              <IonIcon icon={closeOutline} />
            </IonChip>
          )
        }
        {
          loader
            ? (
              <IonSpinner name="crescent" color="secondary" />
            ) : (
              <GrillaDeBanners
                data={banners}
                onEdit={onEdit}
              />
            )
        }
        <IonToast
          isOpen={showMsg !== ""}
          message={showMsg}
          duration={2000}
          onDidDismiss={() => setShowMsg("")}
        />
        <IonLoading
          isOpen={loading !== ""}
          onDidDismiss={() => setLoading("")}
          message={loading}
          duration={60000}
        />
      </IonContent>
      <IonModal
        isOpen={showAbm}
        onDidDismiss={() => setShowAbm(false)}
        swipeToClose={true}
      >
        <AbmBanner
          item={item}
          onDismissModal={onCloseAbm}
          uploadFoto={uploadFoto}
        />
      </IonModal>
    </IonPage>
  );
};

export default BannersPage;
