import React, { useContext } from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route, Redirect, Switch } from 'react-router';
import { podiumOutline, calendarOutline, newspaperOutline } from 'ionicons/icons';
import PracticasPage from './Practicas';
import Novedades from './Novedades';
import Estadisticas from './Estadisticas';
import { AppContext } from '../AppContext';

interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => {

  const { isLogged, vioTutorial } = useContext(AppContext);

  return (
    !isLogged()
      ? <Redirect to="/" />
      : (
        !vioTutorial
          ? <Redirect to="/tutorial" />
          : (
            <IonTabs>
              <IonRouterOutlet>
                <Switch>
                  <Route path="/app/novedades" component={Novedades} exact />
                  <Route path="/app/practicas" component={PracticasPage} exact />
                  <Route path="/app/estadisticas" component={Estadisticas} exact />
                  <Redirect to="/app/novedades" />
                </Switch>
              </IonRouterOutlet>
              <IonTabBar slot="bottom">
                <IonTabButton tab="novedades" href="/app/novedades">
                  <IonIcon icon={newspaperOutline} />
                  <IonLabel>Novedades</IonLabel>
                </IonTabButton>
                <IonTabButton tab="practicas" href="/app/practicas">
                  <IonIcon icon={calendarOutline} />
                  <IonLabel>Prácticas</IonLabel>
                </IonTabButton>
                <IonTabButton tab="estadisticas" href="/app/estadisticas">
                  {/* barChart */}
                  <IonIcon icon={podiumOutline} />
                  <IonLabel>Estadísticas</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          )
      )
  );
};

export default MainTabs;
