import React, { useState, useEffect, useContext } from 'react';
import { IonToolbar, IonContent, IonPage, IonButtons, IonFab, IonFabButton, IonTitle, IonMenuButton, IonIcon, IonToast, IonModal, IonHeader, IonChip, IonLabel, IonSpinner, IonLoading } from '@ionic/react';
import { add, closeOutline, alertCircleOutline } from 'ionicons/icons';
import AbmAdmin from './AbmAdmin';
import axios from 'axios';
import { Usuario } from '../models/Usuario';
import GrillaDeUsuarios from '../components/GrillaDeUsuarios';
import LogoAaot from '../components/LogoAaot';
import './Administradores.scss'
import { Administrador } from '../models/Administrador';
import Log from '../components/Log';
import { AppContext } from '../AppContext';

const AdministradoresPage: React.FC = () => {

  const [item, setItem] = useState<Usuario | undefined>();
  const [showAbm, setShowAbm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState("");  
  const [error, setError] = useState("");
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [showMsg, setShowMsg] = useState("");

  const {
    usuario
  } = useContext(AppContext);
  
  const cargarAdministradores = () => {
    setError("");
    setLoader(true);
    const url = `${process.env.REACT_APP_API_URL}/administradores`;
    axios.get(url)
    .then((res) => {
      // console.dir(res.data.data);
      setUsuarios(res.data.data);
      setLoader(false);
    }).catch((err) => {
      Log.error(
        err,
        usuario,
        `Administradores.tsx » cargarAdministradores()`
      );
      setLoader(false);
      setError(error);
    });
  }

  useEffect(() => {
    cargarAdministradores();
  // eslint-disable-next-line
  }, []);

  const onCreate = () => {
    setItem(undefined);
    setShowAbm(true);
  };

  const onEdit = (user: Usuario) => {
    setItem(user);
    setShowAbm(true);
  };

  const onCloseAbm = (user: Administrador | undefined) => {
    setShowAbm(false);
    setError("");    
    if (user) {
      setLoading("Creando administrador...");
      axios.post(
        `${process.env.REACT_APP_API_URL}/administradores`, user)
        .then(async () => {
          setLoading("");
          setShowMsg("Administrador creado");
          cargarAdministradores();          
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Administradores.tsx » onCloseAbm(): Error al intentar crear el Administrador: ${JSON.stringify(user)}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  const onRequestActivo = (user: Usuario, activo: boolean) => {
    // console.log("onRequestActivo", user, activo);
    setError("");
    setShowAbm(false);    
    if (user) {
      setLoading("Actualizando administrador...");
      axios.patch(
        `${process.env.REACT_APP_API_URL}/usuarios/${user.id}`, { "activo": activo })
        .then(async () => {
          setLoading("");
          cargarAdministradores();
          setShowMsg("Administrador actualizado");          
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Administradores.tsx » onRequestActivo(): Error al intentar editar el Administrador: ${JSON.stringify(user)}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  const onRequestDelete = (user: Usuario) => {
    setError("");
    setShowAbm(false);
    if (user) {
      setLoading("Borrando administrador...");
      axios.delete(
        `${process.env.REACT_APP_API_URL}/usuarios/${user.id}`)
        .then(async () => {          
          setLoading("");
          cargarAdministradores();
          setShowMsg("Administrador borrado");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Administradores.tsx » onRequestDelete(): Error al intentar borrar el Administrador: ${JSON.stringify(user)}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }    
  };

  return (
    <IonPage id="administradores-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Administradores</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        {
          error && (
            <IonChip color="danger" onClick={() => setError("")}>
              <IonIcon icon={alertCircleOutline} color="primary" />
              <IonLabel>{error}</IonLabel>
              <IonIcon icon={closeOutline} />
            </IonChip>
          )
        }
        {
          loader
          ? (
            <IonSpinner name="crescent" color="secondary" />
          ) : (
            <GrillaDeUsuarios
              modo="administradores"
              data={usuarios}
              onEdit={onEdit}
            />
          )
        }
        <IonToast
          isOpen={showMsg !== ""}
          message={showMsg}
          duration={2000}
          onDidDismiss={() => setShowMsg("")}
        />
        <IonLoading
          isOpen={loading !== ""}
          onDidDismiss={() => setLoading("")}
          message={loading}
          duration={60000}
        />
      </IonContent>
      <IonModal
        isOpen={showAbm}
        onDidDismiss={() => setShowAbm(false)}
        swipeToClose={true}
      >
        <AbmAdmin
          item={item}
          onDismissModal={onCloseAbm}
          onRequestActivo={onRequestActivo}
          onRequestDelete={onRequestDelete}
        />
      </IonModal>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="primary" onClick={onCreate}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default AdministradoresPage;
