import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonInput, IonIcon, useIonAlert, IonNote, IonToggle } from '@ionic/react';
import { checkmarkOutline, closeOutline, trashOutline } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { Administrador } from '../models/Administrador';
import { Usuario } from '../models/Usuario';
import './AbmAdmin.scss'
import { PASSWORD_LARGO } from '../constantes';

interface AbmAdminProps {
  item: Usuario | undefined,
  onDismissModal: (usuario: Administrador | undefined) => void;
  onRequestActivo: (usuario: Usuario, activo: boolean) => void;
  onRequestDelete: (usuario: Usuario) => void;  
}

const AbmAdminPage: React.FC<AbmAdminProps> = ({ item, onDismissModal, onRequestActivo, onRequestDelete }) => {

  const [present] = useIonAlert();

  const onDelete = () => {
    if (item) {
      present({
        header: 'Confirmación',
        message: '¿Borrar este Administrador?',
        buttons: [
          'Cancelar',
          { text: 'Borrar', handler: (d) => onRequestDelete(item) },
        ]
      });
    }
  };

  const { control, handleSubmit, getValues, formState: { errors, isValid } } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      id: item?.id || undefined,
      nombre: item?.nombre || "",
      email: item?.email || "",
      activo: item ? item.activo : true,
      password1: "",
      password2: ""
    }
  });

  const onSubmit = (data: any) => {
    if (item) {
      // EDICIÓN
      if (!item.activo !== !data.activo) {
        onRequestActivo(item, data.activo);  
      } else {
        onDismissModal(undefined);
      }
    } else {
      // ALTA
      const usuario: Administrador = {
        id: data.id || null,
        nombre: data?.nombre || "",
        nombre_completo: data?.nombre || "",
        email: data?.email || "",
        activo: data?.activo || true,
        password: data?.password1
      };
      onDismissModal(usuario);      
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage id="abm-novedad">
        <IonHeader translucent={false}>
          <IonToolbar color="primary">
            <IonButtons slot="start">
            </IonButtons>
            <IonTitle>
              {item ? "Editar" : "Nuevo"}&nbsp;Administrador
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismissModal(undefined)}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList lines="full">
            <IonItem className={errors.id ? "ion-invalid" : ""}>
              <IonLabel position="stacked">DNI</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    type="number"
                    autocomplete="off"
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el DNI"
                    disabled={item !== undefined}
                  />
                )}
                control={control}
                name="id"
                rules={{
                  required: {
                    value: true,
                    message: "Campo requerido"
                  },
                  minLength: {
                    value: 7,
                    message: "Mínimo 7 caracteres"
                  }
                }}
              />
              <IonNote slot="error">{errors?.id?.message}</IonNote>
            </IonItem>
            <IonItem className={errors.nombre ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Nombre</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    autocomplete="off"
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el Nombre"
                    disabled={item !== undefined}
                  />
                )}
                control={control}
                name="nombre"
                rules={{
                  required: {
                    value: true,
                    message: "Campo requerido"
                  }
                }}
              />
              <IonNote slot="error">{errors?.nombre?.message}</IonNote>
            </IonItem>
            <IonItem className={errors.email ? "ion-invalid" : ""}>
              <IonLabel position="stacked">Email</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonInput
                    {...field}
                    type="email"
                    autocomplete="off"
                    onIonBlur={() => field.onBlur()}
                    onIonChange={(e) => field.onChange(e.detail.value)}
                    placeholder="Ingrese el Email"
                    disabled={item !== undefined}
                  />
                )}
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: "Campo requerido"
                  },
                  pattern: {
                    // eslint-disable-next-line no-useless-escape
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email no válido"
                  }
                }}
              />
              <IonNote slot="error">{errors?.email?.message}</IonNote>
            </IonItem>
            {
              item === undefined && (
                <>
                  <IonItem className={errors.password1 ? "ion-invalid" : ""}>
                    <IonLabel position="stacked">Contraseña</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          {...field}
                          autocomplete="off"
                          onIonBlur={() => field.onBlur()}
                          onIonChange={(e) => field.onChange(e.detail.value)}
                          placeholder="Ingrese la Contraseña"
                        />
                      )}
                      control={control}
                      name="password1"
                      rules={{
                        required: {
                          value: true,
                          message: "Campo requerido"
                        },
                        minLength: {
                          value: PASSWORD_LARGO,
                          message: `La Contraseña debe poseer al menos ${PASSWORD_LARGO} caracteres`
                        }
                      }}
                    />
                    <IonNote slot="error">{errors?.password1?.message}</IonNote>
                  </IonItem>
                  <IonItem className={errors.password2 ? "ion-invalid" : ""}>
                    <IonLabel position="stacked">Confirmar contraseña</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          {...field}
                          autocomplete="off"
                          onIonBlur={() => field.onBlur()}
                          onIonChange={(e) => field.onChange(e.detail.value)}
                          placeholder="Repita la Contraseña"
                        />
                      )}
                      control={control}
                      name="password2"
                      rules={{
                        required: {
                          value: true,
                          message: "Campo requerido"
                        },
                        minLength: {
                          value: PASSWORD_LARGO,
                          message: `La Contraseña debe poseer al menos ${PASSWORD_LARGO} caracteres`
                        },
                        validate: (value) => {
                          const { password1 } = getValues();
                          return password1 === value || "Las Contraseñas no coinciden";
                        }
                      }}
                    />
                    <IonNote slot="error">{errors?.password2?.message}</IonNote>
                  </IonItem>
                </>
              )
            }
            <IonItem>
              <IonLabel position="fixed">Activo</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonToggle
                    slot="end"
                    checked={field.value}
                    onIonChange={e => field.onChange(e.detail.checked)}
                  />
                )}
                control={control}
                name="activo"
              />
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar color="tertiary">
            {
              item && (
                <IonButtons slot="start">
                  <IonButton onClick={onDelete}>
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                </IonButtons>
              )
            }
            <IonButtons slot="end">
              <IonButton color="success" mode="md" fill="solid" type="submit" disabled={!isValid}>
                Guardar
                <IonIcon slot="end" icon={checkmarkOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default AbmAdminPage;
