import React from "react";
import PropTypes from "prop-types";

// https://dev.to/bravemaster619/simplest-way-to-embed-a-youtube-video-in-your-react-app-3bk2
// https://www.classynemesis.com/projects/ytembed/
// https://youtu.be/tfXW5dVPdr0

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive" style={{ height: '100%', overflow: 'hidden' }}>
        <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://www.youtube.com/embed/${embedId}?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3`}
            // src={`https://www.youtube.com/embed/${embedId}?playlist=${embedId}&loop=0&modestbranding=1&showinfo=0&rel=0&iv_load_policy=3`}            
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Video tutorial"
            allowFullScreen
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
