import React from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Contacto from './Contacto';
import './ContactoModal.scss';

interface ContactoModalProps {
  onDismissModal: () => void;
}

const ContactoModal: React.FC<ContactoModalProps> = ({ onDismissModal }) => {

  return (
    <IonPage id="contacto-modal-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="tertiary">
          <IonTitle>Contacto</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismissModal}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>          
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Contacto />
      </IonContent>
    </IonPage>
  );
};

export default ContactoModal;
