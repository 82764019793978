import React, { Fragment, useContext, useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonPage, IonIcon, IonRadioGroup, IonRadio, IonItemDivider, IonSearchbar } from '@ionic/react';
import { AppContext } from '../AppContext';
import './SelectorDeInstitucion.scss'
import { checkmarkOutline, closeOutline, trashOutline } from 'ionicons/icons';
import { Institucion } from '../models/Institucion';

interface SelectorDeInstitucion {
  onDismissModal: (residencia: any) => void;
  onClose: () => void;
}

const SelectorDeInstitucion: React.FC<SelectorDeInstitucion> = ({ onDismissModal, onClose }) => {

  const {
    esquema,
    usuario
  } = useContext(AppContext);
  const [institucion, setInstitucion] = useState<number | undefined>(usuario?.id_institucion);
  const [institucionesLista, setInstitucionesLista] = useState<Institucion[]>([]);
  const [filtro, setFiltro] = useState("");

  useEffect(() => {
    if (esquema && esquema.instituciones) {
      if (filtro) {
        let regexp = new RegExp(filtro, "i");
        setInstitucionesLista([...esquema.instituciones.filter((i: Institucion) => i.nombre.match(regexp))]);
      } else {
        setInstitucionesLista([...esquema.instituciones]);
      }
    }
  }, [esquema, filtro]);

  const armarProvincia = (provincia: any) => {
    const instituciones = institucionesLista.filter((i: Institucion) => i.id_provincia === provincia.id);
    if (filtro && instituciones.length === 0) {
      return null;
    }
    return (
      <Fragment key={provincia.id}>
        <IonItemDivider color="medium" sticky>
          {provincia.nombre.toUpperCase()}
        </IonItemDivider>
        {
          instituciones.length
          ? (
            instituciones.map((ins: Institucion) => (
              <IonItem key={ins.id}>
                <IonLabel text-wrap>{ins.nombre}</IonLabel>
                <IonRadio slot="end" value={ins.id} />
              </IonItem>
            )
          )) : (
            <IonItem color="light">
              <IonLabel className="gris">(Sin Instituciones)</IonLabel>
            </IonItem>
          )
        }
      </Fragment>
    );
  }

  const guardar = () => {
    const seleccion = institucion
    ? esquema.instituciones.find((i: Institucion) => i.id === institucion)
    : null;
    if (seleccion) {
      onDismissModal(seleccion);  
    } else {
      onClose();
    }    
  }

  const limpiar = () => {
    onDismissModal(null);
  }

  const ListaDeInstituciones = () => {
    return (
      <IonList mode="md" className="ion-no-padding">
        <IonRadioGroup
          value={institucion}
          onIonChange={e => setInstitucion(e.detail.value)}
        >
          {
            esquema.provincias.map((provincia: any) => armarProvincia(provincia))
          }
        </IonRadioGroup>
      </IonList>
    )
  }

  return (
    <IonPage id="selector-instituciones">
      <IonHeader translucent={true} no-border className="instituciones">
        <IonToolbar color="primary">
          <IonTitle>
            Residencia de Origen
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClose()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <IonSearchbar
          mode="ios"
          value={filtro}
          placeholder="Filtrar Instituciones..."
          onIonChange={e => setFiltro(e.detail.value!)}
          showClearButton="always"
          debounce={100}
        >
        </IonSearchbar>
        <ListaDeInstituciones />
      </IonContent>
      <IonFooter>
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonButton onClick={limpiar}>
              <IonIcon icon={trashOutline} />
              &nbsp;Limpiar
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton mode="md" fill="solid" color="success" onClick={guardar}>
              Guardar
              <IonIcon slot="end" icon={checkmarkOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default SelectorDeInstitucion;
