import React, { useState, useEffect, useContext } from 'react';
import { IonToolbar, IonContent, IonPage, IonButtons, IonFab, IonFabButton, IonTitle, IonMenuButton, IonIcon, IonToast, IonModal, IonHeader, IonChip, IonLabel, IonSpinner, IonLoading } from '@ionic/react';
import { add, closeOutline, alertCircleOutline } from 'ionicons/icons';
import AbmInstitucion from './AbmInstitucion';
import axios from 'axios';
import GrillaDeInstituciones from '../components/GrillaDeInstituciones';
import LogoAaot from '../components/LogoAaot';
import { AppContext } from '../AppContext';
import { Institucion } from '../models/Institucion';
import { Provincia } from '../models/Provincia';
import './Instituciones.scss'
import Log from '../components/Log';

const InstitucionesPage: React.FC = () => {

  const [item, setItem] = useState<Institucion | undefined>();
  const [showAbm, setShowAbm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState("");  
  const [error, setError] = useState("");
  const [instituciones, setInstituciones] = useState<Institucion[]>([]);
  const [provincias, setProvincias] = useState<Provincia[]>([]);
  const [showMsg, setShowMsg] = useState("");

  const {
    esquema,
    usuario
  } = useContext(AppContext);

  useEffect(() => {
    setProvincias(esquema.provincias);
  }, [esquema]);

  const cargarInstituciones = () => {
    setError("");
    setLoader(true);
    const url = `${process.env.REACT_APP_API_URL}/instituciones`;
    axios.get(url)
    .then((res) => {
      setInstituciones(res.data);
      setLoader(false);
    }).catch((err) => {
      Log.error(
        err,
        usuario,
        `Instituciones.tsx » cargarInstituciones()`
      );
      setLoader(false);
      setError(error);
    });
  }

  useEffect(() => {
    cargarInstituciones();
  // eslint-disable-next-line
  }, []);

  const onCreate = () => {
    setItem(undefined);
    setShowAbm(true);
  };

  const onEdit = (ins: Institucion) => {
    setItem(ins);
    setShowAbm(true);
  };

  const onCloseAbm = (ins: Institucion | undefined) => {
    setShowAbm(false);
    setError("");
    if (ins) {
      if (ins.id) {
        setLoading("Editando institución...");
        axios.put(
          `${process.env.REACT_APP_API_URL}/instituciones/${ins.id}`, ins)
          .then(async () => {
            setLoading("");
            setShowMsg("Institución editada");
            cargarInstituciones();          
          })
          .catch((err) => {
            Log.error(
              err,
              usuario,
              `Instituciones.tsx » onCloseAbm(): Error al intentar editar la Institución: ${JSON.stringify(ins)}`
            );
            const msg = err && err.response && err.response.data
              ? err.response.data.message
              : 'Error al intentar esta operación';
            setError(msg);
            setLoading("");
          });
      } else {
        setLoading("Creando institución...");
        axios.post(
          `${process.env.REACT_APP_API_URL}/instituciones`, ins)
          .then(async () => {
            setLoading("");
            setShowMsg("Institución creada");
            cargarInstituciones();          
          })
          .catch((err) => {
            Log.error(
              err,
              usuario,
              `Instituciones.tsx » onCloseAbm(): Error al intentar crear la Institución: ${JSON.stringify(ins)}`
            );
            const msg = err && err.response && err.response.data
              ? err.response.data.message
              : 'Error al intentar esta operación';
            setError(msg);
            setLoading("");
          });
      }
    }
  };

  const onRequestDelete = (ins: Institucion) => {
    setError("");
    setShowAbm(false);
    if (ins) {
      setLoading("Borrando institución...");
      axios.delete(
        `${process.env.REACT_APP_API_URL}/instituciones/${ins.id}`)
        .then(async () => {          
          setLoading("");
          cargarInstituciones();
          setShowMsg("Institución borrada");
        })
        .catch((err) => {
          Log.error(
            err,
            usuario,
            `Instituciones.tsx » onRequestDelete(): Error al intentar borrar la Institución ${JSON.stringify(ins)}`
          );
          const msg = err && err.response && err.response.data
            ? err.response.data.message
            : 'Error al intentar esta operación';
          setError(msg);
          setLoading("");
        });
    }
  };

  return (
    <IonPage id="instituciones-page">
      <IonHeader translucent={true} no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <LogoAaot />
          <IonTitle>Instituciones</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        {
          error && (
            <IonChip color="danger" onClick={() => setError("")}>
              <IonIcon icon={alertCircleOutline} color="primary" />
              <IonLabel>{error}</IonLabel>
              <IonIcon icon={closeOutline} />
            </IonChip>
          )
        }
        {
          loader
          ? (
            <IonSpinner name="crescent" color="secondary" />
          ) : (
            <GrillaDeInstituciones
              data={instituciones}
              onEdit={onEdit}
            />
          )
        }
        <IonToast
          isOpen={showMsg !== ""}
          message={showMsg}
          duration={2000}
          onDidDismiss={() => setShowMsg("")}
        />
        <IonLoading
          isOpen={loading !== ""}
          onDidDismiss={() => setLoading("")}
          message={loading}
          duration={60000}
        />
      </IonContent>
      <IonModal
        isOpen={showAbm}
        onDidDismiss={() => setShowAbm(false)}
        swipeToClose={true}
      >
        <AbmInstitucion
          item={item}
          provincias={provincias}
          onDismissModal={onCloseAbm}
          onRequestDelete={onRequestDelete}
        />
      </IonModal>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="primary" onClick={onCreate}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default InstitucionesPage;
